import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import uuid from "uuid/v4";
import { getRegexValidation } from "~/global/utils";

const getFileData = (state) => state.modules.dataImport.importDataAutomatic.fileData;
const getSelectedFilters = (state) => state.modules.dataImport.importDataAutomatic.selectedFilters;
const getUsername = (state) => state.session.user.nickname;
const getCustomerId = (state) => state.customer.activeCustomerId;

let recordingErrors = [];
let recordingRow = 2;
let processResult;

const verifyPassFail = (keyValue) => {
  if (keyValue !== "PASS" && keyValue !== "FAIL") {
    const errorMessage = `Invalid value at processResult on row ${recordingRow}. Must be PASS or FAIL.`;
    recordingErrors.push(errorMessage);
  } else {
    processResult = keyValue;
  }
};

const validateIdentifiers = (recording) => {
  const { IMEI, MEID, wifiMAC, bluetoothMAC, OEMSerialNumber } = recording;

  if (!IMEI || !getRegexValidation("IMEI", IMEI)) {
    recordingErrors.push(`Row ${recordingRow}: IMEI is invalid or empty ("${IMEI}").`);
  }

  if (!MEID || !getRegexValidation("MEID", MEID)) {
    recordingErrors.push(`Row ${recordingRow}: MEID is invalid or empty ("${MEID}").`);
  }

  if (!wifiMAC || !getRegexValidation("wifiMAC", wifiMAC)) {
    recordingErrors.push(`Row ${recordingRow}: wifiMAC is invalid or empty ("${wifiMAC}").`);
  }

  if (!bluetoothMAC || !getRegexValidation("bluetoothMAC", bluetoothMAC)) {
    recordingErrors.push(
      `Row ${recordingRow}: bluetoothMAC is invalid or empty ("${bluetoothMAC}").`
    );
  }

  if (!OEMSerialNumber || !getRegexValidation("OEMSerialNumber", OEMSerialNumber)) {
    recordingErrors.push(
      `Row ${recordingRow}: OEMSerialNumber is invalid or empty ("${OEMSerialNumber}").`
    );
  }
};

const validateDate = (keyValue, key) => {
  const today = moment();
  keyValue = moment(keyValue, "YYYY-MM-DDTHH:mm:ssZZ", true);
  if (keyValue.isValid() === false) {
    // TODO: (We have to discuss before) At csv read time, we should use use now as default startDate. If we do so we could get rid of this case (keyValue.isValid() == false)
    recordingErrors.push(`Date field ${key} is mandatory on row ${recordingRow}`);
  } else if (today.isBefore(keyValue)) {
    recordingErrors.push(`Date field ${key} cannot be in the future on row ${recordingRow}`);
  }
};

const validateMainFailPriority = (keyValue, key) => {
  if (processResult === "FAIL") {
    if (keyValue === "" || keyValue === undefined) {
      generateError(key);
    } else if (isNaN(keyValue) || keyValue < 1 || keyValue > 99) {
      recordingErrors.push(
        `Field ${key} needs to contain a number between 1-99 on row ${recordingRow}`
      );
    }
  }
};

const validateFailPriority = (keyValue, key) => {
  if (processResult === "FAIL" && keyValue && (isNaN(keyValue) || keyValue < 1 || keyValue > 99)) {
    recordingErrors.push(
      `Field ${key} needs to contain a number between 1-99 on row ${recordingRow}`
    );
  }
};

const validateMainFailCode = (keyValue, key) => {
  if (processResult === "FAIL") {
    if (keyValue === "" || keyValue === undefined) {
      generateError(key);
    }
  }
};

const vailidateMainFailDescription = (keyValue, key) => {
  if (processResult === "FAIL") {
    if (keyValue === "" || keyValue === undefined) {
      generateError(key);
    }
  }
};

const validateMainFailCategory = (keyValue, key) => {
  if (processResult === "FAIL") {
    if (keyValue === "" || keyValue === undefined) {
      generateError(key);
    }
  }
};

const validateDuration = (keyvalue, key) => {
  if (!Number.isInteger(parseInt(keyvalue))) {
    generateError(key);
  }
};
const validateFields = (keyValue, key) => {
  let isValid = true;
  if (key === "startTime") {
    validateDate(keyValue, key);
  }

  if (key === "failPriority") {
    validateMainFailPriority(keyValue, key);
  } else if (key.includes("failPriority")) {
    validateFailPriority(keyValue, key);
  }

  if (key === "failCode") {
    validateMainFailCode(keyValue, key);
  }

  if (key === "failDescription") {
    vailidateMainFailDescription(keyValue, key);
  }

  if (key === "failCategory") {
    validateMainFailCategory(keyValue, key);
  }
  if (key === "duration") {
    validateDuration(keyValue, key);
  }
  switch (key) {
    case "manufacturer":
      if (!keyValue) {
        isValid = false;
      }
      break;
    case "marketingName":
      if (!keyValue) {
        isValid = false;
      }
      break;
    case "storageCapacity":
      if (!keyValue) {
        isValid = false;
      }
      break;
    case "deviceColor":
      if (!keyValue) {
        isValid = false;
      }
      break;
    case "operator":
      if (!keyValue) {
        isValid = false;
      }
      break;
  }
  if (!isValid) {
    generateError(key);
  }
};
const generateError = (key) => {
  recordingErrors.push(`Mandatory field "${key}" is empty or invalid on row ${recordingRow}`);
};

export const getCleanInfoData = createSelector([getSelectedFilters], (filters) => {
  const cleanInfoData = {
    faid: (filters.facility || []).faid,
    dpid: (filters.deployment || []).dpid,
    shiftId: (filters.shift || []).id,
    teamId: (filters.team || []).teamid,
    shift: (filters.shift || []).name,
    team: (filters.team || []).name,
    programId: (filters.program || []).id,
    configId: (filters.config || []).id,
  };

  return cleanInfoData;
});

export const getCleanFileData = createSelector([getFileData], (uploadFileData) => {
  const cleanFileData = uploadFileData.reduce((acc, row, index, array) => {
    let keys = array[0];

    keys = keys.map((key) => key.trim());

    if (index > 0) {
      const recording = row.reduce((acc, column, index) => {
        const col = column.trim();

        if (col.charAt(0) === '"' && col.charAt(col.length - 1) === '"') {
          acc[keys[index]] = col.substr(1, col.length - 2);
        } else {
          acc[keys[index]] = col;
        }

        return acc;
      }, {});

      acc.push(recording);
    }

    return acc;
  }, []);

  return cleanFileData;
});

export const getFormattedFileData = createSelector(
  [getCleanFileData, getUsername, getCustomerId],
  (recordings, username, customerId) => {
    for (const recording of recordings) {
      for (const key in recording) {
        const keys = Object.keys(recording);

        if (keys.indexOf("time") < 0) {
          const dateTime = new Date().toLocaleString();
          recording.time = dateTime;
        }

        if (keys.indexOf("operator") < 0) {
          recording.operator = username;
        }

        if (key === "time") {
          const startTime = moment(recording.time);
          const endTime = moment(recording.time);
          endTime.add(5, "minutes");
          recording.startTime = startTime.format("YYYY-MM-DDTHH:mm:ssZZ");
          recording.endTime = endTime.format("YYYY-MM-DDTHH:mm:ssZZ");
        }

        if (key.includes("failPriority")) {
          if (recording[key] !== "") {
            recording[key] = parseInt(recording[key]);
          }
        }

        if (key === "processResult") {
          recording[key] = recording[key].toUpperCase();
        }
      }

      recording.batchSessionId = uuid();

      recording.csid = customerId;
      recording.username = username;

      recordingRow++;
    }

    recordingRow = 1;

    return recordings;
  }
);

export const getDataToUpload = createSelector(
  [getCleanInfoData, getFormattedFileData],
  (cleanInfoData, formattedFileData) => {
    const dataToUpload = [];

    for (const row of formattedFileData) {
      const recording = {
        ...cleanInfoData,
        ...row,
      };

      dataToUpload.push(recording);
    }
    return dataToUpload;
  }
);

export const getRecordingErrors = createSelector([getFormattedFileData], (recordings) => {
  recordingErrors = [];
  recordingRow = 2;

  for (const recording of recordings) {
    if (!("IMEI" in recording && "MEID" in recording && "bluetoothMAC" in recording))
      return [
        "Only CSV files are valid, please use the template available to format the data correctly",
      ];
    for (const key in recording) {
      validateFields(recording[key], key);

      if (key == "processResult") {
        verifyPassFail(recording[key]);
      }
    }

    validateIdentifiers(recording);
    recordingRow++;
  }

  return recordingErrors;
});

export const getLineCount = createSelector([getFormattedFileData], (recordings) => {
  let lineCount = 1;

  for (const recording of recordings) {
    lineCount++;
  }

  return lineCount;
});
