import { createAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

import { CustomerManagement } from "~/services";
import { getRegexValidation } from "~/global/utils";

import selectors from "~/selectors";
import moduleSelectors from "../selectors";

export const fetchProgramBegin = createAction("programManagement/FETCH_PROGRAM_BEGIN");
export const fetchProgramSuccess = createAction("programManagement/FETCH_PROGRAM_SUCCESS");
export const fetchProgramError = createAction("programManagement/FETCH_PROGRAM_ERROR");

export const initProgramBegin = createAction("programManagement/INIT_PROGRAM_BEGIN");
export const initProgramSuccess = createAction("programManagement/INIT_PROGRAM_SUCCESS");
export const initProgramError = createAction("programManagement/INIT_PROGRAM_ERROR");

export const updateProgramSuccess = createAction("programManagement/UPDATE_PROGRAM_SUCCESS");

export const saveProgramBegin = createAction("programManagement/SAVE_PROGRAM_BEGIN");
export const saveProgramSuccess = createAction("programManagement/SAVE_PROGRAM_SUCCESS");
export const saveProgramError = createAction("programManagement/SAVE_PROGRAM_ERROR");

export const createProgramBegin = createAction("programManagement/CREATE_PROGRAM_BEGIN");
export const createProgramSuccess = createAction("programManagement/CREATE_PROGRAM_SUCCESS");
export const createProgramError = createAction("programManagement/CREATE_PROGRAM_ERROR");
export const resetProgramEdition = createAction("programManagement/RESET_PROGRAM_EDITION");

export const exportProgramBegin = createAction("programManagement/EXPORT_PROGRAM_BEGIN");
export const exportProgramSuccess = createAction("programManagement/EXPORT_PROGRAM_SUCCESS");
export const exportProgramError = createAction("programManagement/EXPORT_PROGRAM_ERROR");

export const validateProgramIdentifierBegin = createAction(
  "programManagement/VALIDATE_PROGRAM_IDENTIFIER_BEGIN"
);
export const validateProgramIdentifierSuccess = createAction(
  "programManagement/VALIDATE_PROGRAM_IDENTIFIER_SUCCESS"
);
export const validateProgramIdentifierError = createAction(
  "programManagement/VALIDATE_PROGRAM_IDENTIFIER_ERROR"
);

// operations
export const initProgram = () => async (dispatch, getState) => {
  try {
    dispatch(initProgramBegin());
    const state = getState();
    const languages = selectors.languages.getLanguageCodes(state);
    const displayName = {};

    for (let index = 0; index < languages.length; index++) {
      const language = languages[index];
      displayName[language] = "";
    }

    const program = {
      createdOn: null,
      displayName,
      identifier: "",
      serviceSuites: [],
      csid: "",
    };

    dispatch(initProgramSuccess({ program }));
  } catch (e) {
    console.log(e);
    dispatch(initProgramError({ errors: e }));
  }
};

const parseArgumentListItem = (itemType, data = null) => {
  if (data == null) {
    console.warn("Argument List Item required");
  }
  if (itemType == "argument") {
    return parseServicesArgument(data);
  }
  console.warn("unknown argument list item type:", itemType);

  return data;
};

const parseServicesArgument = (serviceArgument) => {
  const serviceArgumentValue = serviceArgument.value.toString();
  if (serviceArgument.type === "string") {
    serviceArgument.value = serviceArgumentValue || "";
  } else if (serviceArgument.type === "bool") {
    serviceArgument.value = (serviceArgumentValue || "").toLowerCase() === "true";
  } else if (serviceArgument.type === "integer") {
    serviceArgument.value = parseInt(serviceArgumentValue);
  } else if (serviceArgument.type === "float") {
    serviceArgument.value = parseFloat(serviceArgumentValue);
  } else if (serviceArgument.type === "list") {
    const extra = serviceArgument.extra || {};
    serviceArgument.extra = {
      type: extra.type,
      items: (extra.items || []).map((i) => parseArgumentListItem(extra.type, i)),
    };
  } else {
    console.warn(`Unknown service type "${serviceArgument.type}"`);
  }
  return serviceArgument;
};

const parseServicesArguments = (serviceArguments) => {
  const currentServiceArguments = serviceArguments.map((serviceArgument) =>
    parseServicesArgument(serviceArgument)
  );
  return currentServiceArguments;
};

const getProgramParsed = (program) => {
  const parsedProgram = program;
  const { serviceSuites } = parsedProgram;
  const serviceSuitesArguments = (serviceSuites || []).map((serviceSuite) =>
    (serviceSuite.services || []).map((service) => parseServicesArguments(service.arguments || []))
  );

  if (serviceSuitesArguments.length > 0) {
    parsedProgram.serviceSuites = serviceSuites;
  }
  return parsedProgram;
};

const getProgramInfo = async (identifier, displayNameLanguages) => {
  const query = CustomerManagement.gqlBuilder(`{
    program(identifier: "${identifier}") {
      createdOn,
      csid,
      identifier,
        displayName{ ${displayNameLanguages} },
        serviceSuites {
          pgid,
          createdOn,
          description,
          displayName{ ${displayNameLanguages} }
          identifier,
          processName,
          processFlow,
          labelCopies,
          labelType,
          minimumIdentifiers,
          printLabel,
          printOnResults,
          requiredFields,
          servicesTemplateIdentifier,
          isRmaMode,
          sendToNetsuite,
          customPermissions,
          services {
            arguments {
              name,
              type,
              value,
            }
            className,
            displayName { ${displayNameLanguages} },
            identifier,
            isEnabled,
            isOptional,
          }
          workflow
        }
    }
  }`);

  const response = await CustomerManagement.POST("/gql", { query });
  const program = await getProgramParsed(response.payload.data.program);
  return program;
};

export const fetchProgram = (identifier) => async (dispatch, getState) => {
  try {
    dispatch(fetchProgramBegin());
    const state = getState();
    const availableLanguages = selectors.languages.getLanguagesCodesToString(state);

    const program = await getProgramInfo(identifier, availableLanguages);
    dispatch(fetchProgramSuccess({ program }));
  } catch (e) {
    dispatch(fetchProgramError({ errors: e }));
  }
};

export const updateProgram = (program, error) => updateProgramSuccess({ program, error });

export const duplicateProgram = (identifier) => async (dispatch, getState) => {
  const state = getState();

  const programNamesEn = selectors.programs.getProgramEnNames(state);
  const availableLanguages = selectors.languages.getLanguagesCodesToString(state);

  const languages = selectors.languages.getLanguagesEmptyValue(state);
  const programIdentifiers = selectors.programs.getProgramIdentifiers(state);

  const program = await getProgramInfo(identifier, availableLanguages);

  let copyName = `Copy of ${program.displayName.en}`;
  let copyIdentifier = `CopyOf${program.identifier}`;

  for (let index = 0; index < programNamesEn.length; index++) {
    if (index === 0 && !programNamesEn.includes(copyName)) {
      break;
    } else if (index > 0 && !programNamesEn.includes(`${copyName} ${index}`)) {
      copyName = `${copyName} ${index}`;
      break;
    }
  }

  for (let index = 0; index < programIdentifiers.length; index++) {
    if (index === 0 && !programIdentifiers.includes(copyIdentifier)) {
      break;
    } else if (index > 0 && !programIdentifiers.includes(`${copyIdentifier}${index}`)) {
      copyIdentifier = `${copyIdentifier}${index}`;
      break;
    }
  }

  const newProgramIdentity = {
    identifier: copyIdentifier,
    displayName: {
      ...languages,
      en: copyName,
    },
  };

  const newProgram = { ...program, ...newProgramIdentity };
  const response = dispatch(createProgram(newProgram));
  return response;
};

export const exportProgram = (identifier) => async (dispatch) => {
  dispatch(exportProgramBegin());
  try {
    const { message } = await CustomerManagement.POST("/program/export", { identifier });
    dispatch(exportProgramSuccess());
    return message;
  } catch (e) {
    if (e instanceof Error) throw e;
    const errMessage = e?.json?.message ?? "Request didn't complete successfully";
    dispatch(exportProgramError(errMessage));
    // reject promise on error, so we can display an error notification
    throw errMessage;
  }
};

export const onChangeProgramIdentifier = (identifier) => async (dispatch) => {
  const program = {
    identifier,
  };
  dispatch(updateProgram(program));
};

export const onChangeDisplayName = (lang, value) => async (dispatch, getState) => {
  const state = getState();
  const currentProgram = moduleSelectors.programEditor.getUpdatedProgram(state);

  const trimmedName = value.trim();
  const isValid = getRegexValidation("Facility_Name", trimmedName);
  const error = { [lang]: !isValid && trimmedName.length > 0 };

  const displayName = {
    ...currentProgram.displayName,
    [lang]: trimmedName,
  };

  const program = {
    displayName: {
      ...displayName,
    },
  };

  dispatch(updateProgram(program, error));
};

export const onClickDeleteServiceSuite = (suiteIdentifier) => async (dispatch, getState) => {
  const state = getState();
  const currentProgram = moduleSelectors.programEditor.getUpdatedProgram(state);
  const requiredCompleted = moduleSelectors.programEditor.getRequiredCompleted(state);

  const serviceSuites = (currentProgram.serviceSuites || []).filter(
    (serviceSuite) => suiteIdentifier != serviceSuite.identifier
  );

  const program = {
    ...currentProgram,
    serviceSuites,
  };

  dispatch(updateProgram(program));

  if (requiredCompleted) {
    dispatch(saveProgram(program));
  }
};

export const clearProgramEdition = () => resetProgramEdition();

const serverServicesArgument = (serviceArgument) => {
  const serviceArgumentValue = serviceArgument.value?.toString();
  serviceArgument.value = serviceArgumentValue || "";
  return serviceArgument;
};

const serverServicesArguments = (serviceArguments) => {
  const currentServiceArguments = serviceArguments.map((serviceArgument) =>
    serverServicesArgument(serviceArgument)
  );
  return currentServiceArguments;
};

const serverServices = (services) => {
  const serverServices = services.map((service) => {
    if (service.className === "DeviceActivationService" && !service.isEnabled) {
      return {
        arguments: [],
        className: "WaitForDeviceActivatedService",
        displayName: { en: "WaitForDeviceActivatedService", es: "", fr: "" },
        identifier: "WaitForDeviceActivatedService",
        isEnabled: true,
        isOptional: true,
      };
    }

    return service;
  });

  return serverServices;
};

const programToServer = (program = {}) => {
  const serviceSuites = cloneDeep(program.serviceSuites);
  const serviceSuitesArguments = (serviceSuites || []).map((serviceSuite) =>
    (serviceSuite.services || []).map((service) => serverServicesArguments(service.arguments || []))
  );

  const parsedProgram = { ...program };

  const serverServiceSuites = (serviceSuites || []).map((serviceSuite) => {
    const services = serverServices(serviceSuite.services);

    return { ...serviceSuite, services };
  });

  if (serviceSuitesArguments.length > 0) {
    parsedProgram.serviceSuites = serverServiceSuites;
  }

  return parsedProgram;
};

export const createProgram =
  (newProgram = {}) =>
  async (dispatch) => {
    const programServerFormat = await programToServer(newProgram);
    try {
      dispatch(createProgramBegin());
      if (!programServerFormat) {
        throw Error(`Parameter "program" required when saving a program`);
      }
      const response = await CustomerManagement.POST("/programs", programServerFormat);
      const program = await getProgramParsed(response.payload);

      dispatch(createProgramSuccess({ program }));
      return true;
    } catch (e) {
      const message =
        e.json != null && e.json.message != null
          ? e.json.message
          : "Request didn't complete successfully";
      dispatch(createProgramError({ errors: message }));
      return false;
    }
  };

export const saveProgram = (newProgram) => async (dispatch) => {
  const programServerFormat = await programToServer(newProgram);
  try {
    dispatch(saveProgramBegin());
    if (!newProgram.identifier) {
      dispatch(
        saveProgramError({
          errors: `Program field "identifier" is required when updating a program`,
        })
      );
    }

    if (!newProgram.createdOn) {
      dispatch(
        saveProgramError({
          errors: `Program field "createdOn" should be present when updating a program (can't update a prograde that doesn't exist)`,
        })
      );
    }
    const response = await CustomerManagement.PUT(
      `/programs/${newProgram.identifier}`,
      programServerFormat
    );
    const program = await getProgramParsed(response.payload);
    dispatch(saveProgramSuccess({ program }));
    return true;
  } catch (e) {
    const err = Object.values(e.json.errors)[0].pgid;
    const message =
      e.json != null && e.json.message != null
        ? err || e.json.errors
        : "Request didn't complete successfully";
    dispatch(saveProgramError({ errors: message }));
    return false;
  }
};

export const validateProgramIdentifier = (identifier) => async (dispatch) => {
  try {
    dispatch(validateProgramIdentifierBegin());

    if (identifier.length > 0) {
      await CustomerManagement.GET(`/program/${identifier}`);
      dispatch(validateProgramIdentifierSuccess());
    } else {
      dispatch(validateProgramIdentifierError({ errors: "Program Identifier is required" }));
    }
  } catch (e) {
    console.log(e);
    const message =
      e.json != null && e.json.message != null
        ? e.json.message
        : "Request didn't complete successfully";
    dispatch(validateProgramIdentifierError({ errors: message }));
  }
};
