import { createAction } from "@reduxjs/toolkit";
import { CustomerManagement } from "~/services";
import selectors from "~/selectors";

export const fetchProgramsBegin = createAction("FETCH_PROGRAMS_BEGIN");
export const fetchProgramsSuccess = createAction("FETCH_PROGRAMS_SUCCESS");
export const fetchProgramsError = createAction("FETCH_PROGRAMS_ERROR");
export const clearProgramsError = createAction("CLEAR_PROGRAMS_ERROR");

export const deleteProgramBegin = createAction("DELETE_PROGRAM_BEGIN");
export const deleteProgramSuccess = createAction("DELETE_PROGRAM_SUCCESS");
export const deleteProgramError = createAction("DELETE_PROGRAM_ERROR");

export const fetchPrograms = () => async (dispatch, getState) => {
  const state = getState();
  const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);
  dispatch(fetchProgramsBegin());
  const query = CustomerManagement.gqlBuilder(`{
    programs {
      createdOn,
      csid,
      displayName { ${displayNameLanguages} },
      identifier,
      serviceSuites {
        pgid,
        createdOn,
        description,
        displayName{ ${displayNameLanguages} },
        identifier,
        labelCopies,
        labelType,
        minimumIdentifiers,
        printLabel,
        printOnResults,
        requiredFields,
        servicesTemplateIdentifier,
        isRmaMode,
        customPermissions,
        sendToNetsuite,
        services {
          arguments {
            name,
            type,
            value,
          }
          className,
          displayName { ${displayNameLanguages} },
          identifier,
          isEnabled,
          isOptional,
        }
      },
      usedByDeployments,
    }
  }`);

  try {
    const response = await CustomerManagement.POST("/gql", { query });
    const programs = response.payload.data.programs || [];
    dispatch(fetchProgramsSuccess({ programs }));
    return programs;
  } catch (e) {
    if (e instanceof Error) throw e;
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(fetchProgramsError({ errors: message }));
  }
};

export const fetchProgramsPreview = () => async (dispatch, getState) => {
  const state = getState();
  const displayNameLanguages = selectors.languages.getLanguagesCodesToString(state);

  dispatch(fetchProgramsBegin());
  const query = CustomerManagement.gqlBuilder(`{
    programs {
      createdOn,
      displayName {
        ${displayNameLanguages}
      },
      identifier,
      serviceSuites {
        pgid,
        identifier,
      },
      usedByDeployments,
    }
  }`);

  try {
    const response = await CustomerManagement.POST("/gql", { query });
    const programs = response.payload.data.programs || [];
    dispatch(fetchProgramsSuccess({ programs }));
  } catch (e) {
    if (e instanceof Error) throw e;
    const message = e.json?.message ?? "Request didn't complete successfully";
    dispatch(fetchProgramsError({ errors: message }));
  }
};

export const clearErrors = () => clearProgramsError();

export const deleteProgram =
  (currentProgram = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentPrograms = [].concat(state.programs.items);
    const { identifier } = currentProgram;
    const programs = currentPrograms.filter((program) => program.identifier != identifier);
    dispatch(deleteProgramBegin({ programs }));
    try {
      await CustomerManagement.DELETE(`/programs/${identifier}`);
      dispatch(deleteProgramSuccess());
      return true;
    } catch (e) {
      if (e instanceof Error) throw e;
      const message = e.json?.message ?? "Request didn't complete successfully";
      dispatch(deleteProgramError({ errors: message, programs: currentPrograms }));
      return false;
    }
  };
