import React, { useEffect, useMemo } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
  ArrowLeftOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  Progress,
  Row,
  Space,
  Table,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import classNames from "classnames";
import { parseISO } from "date-fns";
import { chain, isEmpty } from "lodash";
import prettyMilliseconds from "pretty-ms";
import { useNavigate, createSearchParams, useMatch } from "react-router-dom-v5-compat";
import { green, red } from "@ant-design/colors";

import { ColumnGroupType, ColumnType } from "antd/es/table";
import PrintableInformation from "~/device-history/components/PrintableInformation/PrintableInformation.component";
import {
  fetchProcessRun,
  fetchSummary,
  getDeviceIsWipedByService,
  getShowSessionsList,
  summaryHistory,
} from "~/entities/process-runs/model/processRunsSlice";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import {
  deviceProcessesSelectors,
  fetchDeviceProcesses,
} from "~/pages/reporting/model/deviceProcessesSlice";
import { NoWrap } from "~/shared/ui/NoWrap";
import { fetchModelImage } from "../model/modelImageSlice";
import selectors from "~/selectors";
import actions from "~/actions";
import "./DeviceHistoryPage.scss";
import { REPORTING_SEARCH_PARAMS_VERSION } from "~/pages/reporting/model/constants";
import { Test, ProgressBar } from "~/entities/process-runs/model/processRunTypes";
import { useParams } from "react-router-dom-v5-compat";

const { Text } = Typography;
const { Panel } = Collapse;

export const DeviceHistoryPage = () => {
  const { t } = useTranslation("deviceHistory");
  const match = useMatch("/reporting/process-run/:sessionId/*");
  const isSummary = match?.pathname.includes("Summary") ?? false;
  const chosenSessionIdWithPath =
    match?.pathname.includes("Summary") ?? false
      ? `${match?.params.sessionId ?? ""}/Summary`
      : match?.params.sessionId;
  const chosenSessionId = match?.params.sessionId;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const customFieldNames = useAppSelector((state) =>
    selectors.customFields.getCustomFieldsByName(state)
  );
  const showSessionsList = useAppSelector(getShowSessionsList);
  const showWipeCertificate = useAppSelector(getDeviceIsWipedByService);
  const processRunCheck = useAppSelector((state) => state.reportingModule.processRuns.selected);
  const summary = useAppSelector(summaryHistory);
  const processRun = isSummary ? summary.recording : processRunCheck;
  const modelImage = useAppSelector((state) => state.reportingModule.modelImage.value);
  const allDeviceProcesses = useAppSelector(deviceProcessesSelectors.selectAll);
  const showDialog = useAppSelector(actions.dialog.showDialog);
  const deviceInfo = processRun?.deviceInfo;
  const process = processRun?.process;
  const failDetails = processRun?.failDetails;
  const failReason = failDetails?.failReason;
  const tests = processRun?.tests;
  const batteryInformation = processRun?.batteryInformation;
  const partsCheck = processRun?.deviceInfo?.partsCheck;
  const services = processRun?.services;
  const softwareInformation = processRun?.desktopInformation?.softwareInformation;
  const configurationInformation = processRun?.process?.configurationInformation;
  const desktopInformation = processRun?.desktopInformation;
  const inputOrigin = processRun?.inputOrigin;
  const placeholderImageUrl = "https://storage.googleapis.com/mm-device-images/placeholder/large";
  const resultBadgeClass = classNames("result-badge", {
    "result-badge-pass": processRun?.processResult === "PASS",
    "result-badge-fail": processRun?.processResult === "FAIL",
    "result-badge-incomplete": processRun?.processResult === "INCOMPLETE",
  });
  const { sessionId } = useParams();
  const hasValidSessionId = useMemo(
    () => typeof sessionId === "string" && sessionId.trim().length > 0,
    [sessionId]
  );

  const isDataLoading = !processRun;

  const deviceImage = useMemo(() => {
    if (modelImage != null) {
      return modelImage;
    }
    if (
      deviceInfo?.deviceType?.toLowerCase() === "watch" ||
      deviceInfo?.deviceCategory?.toLowerCase() === "watch"
    ) {
      return `${placeholderImageUrl}/iwatch.png`;
    }
    if (
      deviceInfo?.deviceType?.toLowerCase() === "tablet" ||
      deviceInfo?.deviceCategory?.toLowerCase() === "tablet"
    ) {
      return `${placeholderImageUrl}/ipad.png`;
    }
    if (
      deviceInfo?.deviceType?.toLowerCase() === "laptop" ||
      deviceInfo?.deviceCategory?.toLowerCase() === "laptop"
    ) {
      return `${placeholderImageUrl}/laptop.png`;
    }
    if (deviceInfo?.manufacturer?.toLowerCase().includes("apple") ?? false) {
      return `${placeholderImageUrl}/iphone.png`;
    }

    return `${placeholderImageUrl}/android.png`;
  }, [deviceInfo, modelImage]);

  const sortedTests = useMemo(() => chain(tests).orderBy("order", "asc").value(), [tests]);
  /**
   * This TypeScript method is used to filter and deduplicate an array of tests based on specific criteria.
   * It takes the `sortedTests` array as input and performs the following steps:
   * 1. Removes audits from the `sortedTests` array.
   * 2. Removes duplicates based on the `name` property, keeping the test with the highest `order` value.
   * 3. Returns the filtered and deduplicated array of tests.
   *
   * @returns An array of tests after applying the filtering and deduplication logic.
   */
  const realTests = useMemo(() => {
    // Step 1: Remove audits
    const filteredTests = sortedTests.filter(({ isAudit }: Test) => !(isAudit ?? false));

    // Step 2: Remove duplicates based on name, keeping the one with the highest order value
    const uniqueTests: Test[] = filteredTests.reduce((acc: Test[], current: Test) => {
      const existingTest = acc.find((test: Test) => test.name === current.name);
      const existingTestOrder = existingTest?.order ?? -1;
      const currentTestOrder = current?.order ?? -1;

      if (!existingTest || existingTestOrder < currentTestOrder) {
        return [...acc.filter((test: Test) => test.name !== current.name), current];
      }
      return acc;
    }, []);

    // Step 3: Return the filtered and deduplicated array of tests
    return uniqueTests;
  }, [sortedTests]);

  const auditTests = useMemo(() => sortedTests.filter(({ isAudit }) => isAudit), [sortedTests]);
  const statusColorMapping = {
    PASS: "#5cd9a7",
    FAIL: "#e55e67",
    SKIPPED: "#fa8c16",
    "N/A": "#f8ac1c",
  };
  const progressBar = (record: ProgressBar) => {
    if (record.status === "SKIPPED") {
      return (
        <Tooltip title="SKIPPED">
          <Progress
            percent={0}
            status="normal"
            strokeColor="#fa8c16"
            showInfo
            format={() => "SKIPPED"}
          />
        </Tooltip>
      );
    }
    const statusMapping = {
      "N/A": "N/A",
      FAIL: "PASS",
      PASS: "FAIL",
    };
    let status;
    let passCount;
    let successPercentage;
    if (record.isAudit === true) {
      status = statusMapping[record.status as keyof typeof statusMapping] || "FAIL";
      record.passCount = record.passCount === undefined ? 0 : record.passCount;
      passCount = record.count - record.passCount;
      successPercentage = (passCount * 100) / record.count;
    } else {
      status = record.status ? record.status : record.result;
      passCount = record.passCount;
      successPercentage = record.successPercentage;
    }

    // Ajouter une condition pour le statut "N/A"
    if (status === "N/A") {
      status = "active";
    }

    return (
      <Tooltip
        key={record.name}
        title={`${passCount != null ? passCount : 0} Success / ${
          (record.count || 0) - (passCount || 0)
        } FAIL`}
      >
        <Progress
          className={status !== "PASS" && status !== "FAIL" ? "status-not-pass-fail" : ""}
          key={record.name}
          percent={Number(successPercentage?.toFixed(1) || 0)}
          success={{
            percent: passCount,
          }}
          status={status === "PASS" ? "success" : status === "active" ? "active" : "exception"}
          strokeColor={status === "active" ? "rgb(248, 172, 28)" : green[5]}
          trailColor={status !== "FAIL" && status !== "PASS" ? "rgb(248, 172, 28)" : red[5]}
          showInfo={status === "PASS" ? true : status === "FAIL"}
        />
      </Tooltip>
    );
  };

  const formatBatteryCapacity = (value: string | undefined) => `${value ?? ""}%`;

  const taggifyString = (
    value: string | undefined,
    first_tag_always_default = false,
    formatFunction?: (value: string) => React.ReactNode
  ) => {
    let color = "default";
    if (!isSummary) return formatFunction ? formatFunction(value ?? "") : value;
    if (value == null) return null;
    const values = value.split("|");
    if (values.length > 1 && !first_tag_always_default) {
      color = "volcano";
    }

    return values.map((value, _index) => {
      if (_index === 1 && first_tag_always_default) {
        color = "volcano";
      }
      return (
        <React.Fragment key={value}>
          <Tag color={color}>
            <strong>{formatFunction ? formatFunction(value) : <strong>{value}</strong>}</strong>
          </Tag>
        </React.Fragment>
      );
    });
  };

  const summaryDisplayProgressBar = (): ColumnGroupType<Test> | ColumnType<Test> => {
    if (isSummary) {
      return {
        title: "Summary",
        render: (_, record) => progressBar(record as unknown as ProgressBar),
      };
    }
    return {};
  };

  const createDateColumnConfig = (title: string): ColumnGroupType<Test> | ColumnType<Test> => {
    if (isSummary) {
      return {
        title,
        dataIndex: "startTime",
        render: (_, { startTime }) => startTime != null && isoToLongDate(startTime),
        sorter: ({ startTime: a }, { startTime: b }) => {
          if (a == null || b == null) {
            if (a != null) {
              return 1;
            }

            if (b != null) {
              return -1;
            }

            // If both are null
            return 0;
          }

          return new Date(a).getTime() - new Date(b).getTime();
        },
        defaultSortOrder: "ascend",
      };
    }
    return {
      title,
      dataIndex: "startTime",
      render: (_, { startTime }) => startTime != null && isoToLongDate(startTime),
    };
  };

  // Effect 1: Dispatch actions when chosenSessionId is available.
  useEffect(() => {
    if (typeof chosenSessionId === "string" && chosenSessionId.trim().length > 0) {
      if (isSummary && allDeviceProcesses.length > 0) {
        void dispatch(
          fetchSummary({
            sessionId: chosenSessionId,
            processRuns: allDeviceProcesses,
          })
        );
      } else {
        void dispatch(fetchProcessRun(chosenSessionId));
      }
      if (allDeviceProcesses.length <= 0) {
        void dispatch(fetchDeviceProcesses(chosenSessionId));
      }
    }
  }, [chosenSessionId, isSummary, allDeviceProcesses, dispatch]);

  useEffect(() => {
    if (isDataLoading) return;

    if (hasValidSessionId) return;

    const timer = setTimeout(() => {
      if (!hasValidSessionId) {
        navigate("/reporting");
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [hasValidSessionId, navigate, isDataLoading]);
  useEffect(() => {
    if (deviceInfo?.IMEI != null) {
      void dispatch(fetchModelImage(deviceInfo?.IMEI));
    }
  }, [deviceInfo, dispatch]);

  const isoToLongDate = (date: string) =>
    t("common:datetime", {
      val: parseISO(date),
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

  const navigateToDeviceHistory = () => {
    const searchParams = createSearchParams({
      facilityId: "all",
      timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateRange: "today",
      uniqueness: "last-in",
      reportTypeName: "processRuns",
      v: REPORTING_SEARCH_PARAMS_VERSION,
    });

    navigate(
      {
        pathname: "/reporting",
        search: searchParams.toString(),
      },
      { state: { triggerSearch: true } }
    );
  };
  if (!hasValidSessionId) {
    return <div>pas de id</div>;
  }
  const renderDuration = (duration: string, unit: string) => {
    const parseDuration =
      duration.replaceAll(/\D/g, "") !== "" ? duration.replaceAll(/\D/g, "") : "0";
    return unit === "s"
      ? prettyMilliseconds(Number(parseDuration) * 1000, { secondsDecimalDigits: 0 })
      : unit === "ms"
      ? prettyMilliseconds(Number(parseDuration), { secondsDecimalDigits: 0 })
      : unit === "ns"
      ? prettyMilliseconds(Number(parseDuration) / 1e6, { secondsDecimalDigits: 0 })
      : `${parseDuration} ${unit}`;
  };

  return (
    <Col className="device-history-page">
      {/* TODO: refactor the certificates and printable information */}
      <Row justify="space-between" style={{ width: "100%", marginBottom: "1em" }}>
        <Button
          icon={<ArrowLeftOutlined />}
          size="large"
          onClick={() => {
            navigateToDeviceHistory();
          }}
        >
          {t("go-to-reporting")}
        </Button>
        {customFieldNames && process && processRun && chosenSessionId && (
          <PrintableInformation
            customFieldNames={customFieldNames}
            customFields={process.customFields}
            sessionId={chosenSessionId}
            sessionSelected={processRun}
            showDialog={showDialog}
            showSessionsList={showSessionsList}
            showWipeCertificate={showWipeCertificate}
          />
        )}
      </Row>
      <Row wrap={false} data-testid="new-device-history">
        <Col flex="300px">
          <Timeline mode="left" className="process-run-history">
            {allDeviceProcesses.map(({ endDateTime, name, result, sessionId }) => {
              const timelineItemClass = classNames({
                "timeline-item-pass": result === "PASS",
                "timeline-item-fail": result === "FAIL",
                "timeline-item-incomplete": result === "INCOMPLETE",
                "timeline-item-summary": name === "Summary",
                "timeline-item-active":
                  sessionId === process?.sessionIDHumanReadable ||
                  sessionId === chosenSessionIdWithPath,
              });
              return (
                <Timeline.Item
                  key={sessionId}
                  label={t("common:datetime", {
                    val: parseISO(endDateTime),
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                  // @ts-expect-error -- onClick prop does not exist on type TimelineItemProps
                  onClick={() => {
                    // Putting the full path because the relative option doesn't seem to be supported in the compat package
                    navigate(`/reporting/process-run/${sessionId}`);
                  }}
                  className={timelineItemClass}
                >
                  {name}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </Col>

        <Col flex="auto">
          <Space direction="vertical" size="middle" className="process-run-info">
            <Row justify="space-between" align="middle">
              <Col>
                <Text strong title={t("imei")} style={{ fontSize: "1.5em" }}>
                  {deviceInfo?.IMEI}
                </Text>
              </Col>
              <Col>
                <Space size={4} align="center" className={resultBadgeClass}>
                  {processRun?.processResult === "PASS" ? (
                    <CheckOutlined />
                  ) : processRun?.processResult === "FAIL" ? (
                    <CloseOutlined />
                  ) : isSummary ? (
                    <HistoryOutlined />
                  ) : (
                    <WarningOutlined />
                  )}
                  <span>{isSummary ? "Summary" : processRun?.processResult}</span>
                </Space>
              </Col>
            </Row>

            <Card title={t("device-info")} bordered={false}>
              <Row gutter={16} wrap={false} align="middle">
                <Col flex="none">
                  <img src={deviceImage} alt={t("model-image")} style={{ maxHeight: 251 }} />
                </Col>

                <Col flex="auto">
                  <Descriptions bordered column={2} size="middle">
                    <Descriptions.Item label={t("meid")}>{deviceInfo?.MEID}</Descriptions.Item>
                    <Descriptions.Item label={t("serial-number")}>
                      {taggifyString(deviceInfo?.OEMSerialNumber)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("manufacturer")}>
                      {taggifyString(deviceInfo?.manufacturer)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("manufacturer-part-number")}>
                      {taggifyString(deviceInfo?.MPN)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("embedded-identity-document")}>
                      {taggifyString(deviceInfo?.EID)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("model-name")}>
                      {taggifyString(deviceInfo?.modelName)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("model-number")}>
                      {taggifyString(deviceInfo?.modelNumber)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("product-type")}>
                      {taggifyString(deviceInfo?.productType)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("os-version")}>
                      {taggifyString(deviceInfo?.osVersion)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("storage-capacity")}>
                      {taggifyString(deviceInfo?.storageCapacity)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("device-color")}>
                      {taggifyString(deviceInfo?.deviceColor)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("device-category")}>
                      {taggifyString(deviceInfo?.deviceCategory)}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
            <Card title={t("process-info")} bordered={false}>
              <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Descriptions bordered column={2} size="middle">
                  <Descriptions.Item label={t("session-id")}>
                    {taggifyString(process?.sessionIDHumanReadable)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("cross-system-id")}>
                    {taggifyString(process?.crossSystemId)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("start-date")}>
                    {process?.startDateTime != null && isoToLongDate(process.startDateTime)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("end-date")}>
                    {process?.endDateTime != null && isoToLongDate(process.endDateTime)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("duration")}>
                    {process?.time != null && process?.timeUnits != null && (
                      <NoWrap>{renderDuration(process.time, process.timeUnits)}</NoWrap>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("process-name")}>
                    {taggifyString(process?.name)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("process-flow")}>
                    {taggifyString(process?.flow)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("process-route")}>
                    {taggifyString(process?.route)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("shift")}>
                    {taggifyString(process?.shift)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("team")}>
                    {taggifyString(process?.team)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("operator")}>
                    {taggifyString(process?.operator)}
                  </Descriptions.Item>
                </Descriptions>

                {process?.customFields && (
                  <Descriptions title={t("custom-fields")} bordered column={2} size="middle">
                    {Object.entries(process.customFields).map(([key, value]) => (
                      <Descriptions.Item key={key} label={key}>
                        {taggifyString(value.toString())}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                )}
              </Space>
            </Card>
            {processRun?.processResult === "FAIL" && (
              <Card title={t("fail-reason")} bordered={false}>
                <Table
                  dataSource={
                    failDetails?.failCodes
                      ?.filter((code) => code.code !== "DEVICE_DIAGNOSTICS_FAIL")
                      .reduce<typeof failDetails.failCodes>((acc, code) => {
                        // If this is the main fail reason, add it to the beginning
                        const isMainFailReason = Boolean(
                          failReason &&
                            code.code === failReason.code &&
                            (code.priority ?? 0) === (failReason.priority ?? 0)
                        );
                        if (isMainFailReason) {
                          return [code, ...acc];
                        }
                        // Otherwise add it to the end
                        return [...acc, code];
                      }, []) || []
                  }
                  columns={[
                    {
                      title: t("fail.priority"),
                      dataIndex: "priority",
                      key: "priority",
                      sorter: (a, b) => {
                        const priorityA = a.priority ?? 0;
                        const priorityB = b.priority ?? 0;
                        return priorityA - priorityB;
                      },
                      render: (text: string | number, record) => {
                        const isMainFailReason = Boolean(
                          failReason &&
                            record.code === failReason.code &&
                            (record.priority ?? 0) === (failReason.priority ?? 0)
                        );
                        return isMainFailReason ? (
                          <span className="priority-value">{text}</span>
                        ) : (
                          text
                        );
                      },
                    },
                    {
                      title: t("fail.category"),
                      dataIndex: "category",
                      key: "category",
                      sorter: (a, b) => (a.category || "").localeCompare(b.category || ""),
                      render: (text) => taggifyString(text),
                    },
                    {
                      title: t("fail.code"),
                      dataIndex: "code",
                      key: "code",
                      sorter: (a, b) => (a.code || "").localeCompare(b.code || ""),
                      render: (text) => taggifyString(text),
                    },
                    {
                      title: t("fail.description"),
                      dataIndex: "description",
                      key: "description",
                      render: (text) => taggifyString(text),
                    },
                  ]}
                  rowKey={(record) => `${record.code}-${record.priority}`}
                  pagination={false}
                  rowClassName={(record) => {
                    const isMainFailReason = Boolean(
                      failReason &&
                        record.code === failReason.code &&
                        (record.priority ?? 0) === (failReason.priority ?? 0)
                    );
                    return isMainFailReason ? "main-fail-reason" : "";
                  }}
                  locale={{
                    emptyText: t("no-failure-codes-available"),
                  }}
                />
              </Card>
            )}
            <Card title={t("tests")} bordered={false}>
              <Table
                key={process?.sessionIDHumanReadable}
                dataSource={realTests}
                columns={[
                  {
                    title: t("test.name"),
                    dataIndex: "name",
                    render: (_, { title, name }) =>
                      !isEmpty(title?.en)
                        ? taggifyString(title?.en, true)
                        : taggifyString(name, true),
                  },
                  summaryDisplayProgressBar(),
                  {
                    title: t("test.status"),
                    dataIndex: "status",
                    render: (_, { status }) => {
                      const testStatus = status === "SKIPPED" ? "SKIPPED" : status;
                      const color =
                        statusColorMapping[testStatus as keyof typeof statusColorMapping] ||
                        "#f8ac1c";
                      return <b style={{ color }}>{testStatus}</b>;
                    },
                  },
                  createDateColumnConfig(t("test.start")),
                  {
                    title: t("test.duration"),
                    dataIndex: "duration",
                    render: (_, { duration, durationUnits }) =>
                      duration != null && durationUnits != null
                        ? renderDuration(duration, durationUnits)
                        : null,
                  },
                  {
                    title: t("test.fail-codes"),
                    dataIndex: "failCodes",
                    render: (_, { failCodes }) =>
                      failCodes &&
                      failCodes
                        .map(({ code, priority }) =>
                          code != null && priority != null ? `${code} (${priority})` : undefined
                        )
                        .filter((f) => Boolean(f))
                        .join(", "),
                  },
                ]}
                rowKey="order"
                pagination={false}
              />
            </Card>
            {auditTests.length > 0 && (
              <Collapse>
                <Panel header={t("audit-tests")} key="1">
                  <Table
                    key={process?.sessionIDHumanReadable}
                    dataSource={auditTests}
                    columns={[
                      {
                        title: t("test.name"),
                        dataIndex: "name",
                        render: (_, { name }) => taggifyString(name, true),
                      },
                      summaryDisplayProgressBar(),
                      {
                        title: t("test.status"),
                        dataIndex: "status",
                        render: (_, { status }) => {
                          // If the audit test was failed it means the operator passed the audit
                          const auditStatus =
                            status === "FAIL" ? "PASS" : status === "N/A" ? "N/A" : "FAIL";
                          const color = statusColorMapping[auditStatus] || "#f8ac1c";

                          return <b style={{ color }}>{auditStatus}</b>;
                        },
                      },
                      createDateColumnConfig(t("test.start")),
                      {
                        title: t("test.duration"),
                        dataIndex: "duration",
                        render: (_, { duration, durationUnits }) =>
                          duration != null && durationUnits != null
                            ? renderDuration(duration, durationUnits)
                            : null,
                      },
                      {
                        title: t("test.fail-codes"),
                        dataIndex: "failCodes",
                        render: (_, { failCodes }) =>
                          failCodes &&
                          failCodes
                            .map(({ code, priority }) =>
                              code != null && priority != null ? `${code} (${priority})` : undefined
                            )
                            .filter((f) => Boolean(f))
                            .join(", "),
                      },
                    ]}
                    rowKey="order"
                    pagination={false}
                  />
                </Panel>
              </Collapse>
            )}
            <Card title={t("battery-info")} bordered={false}>
              <Descriptions bordered column={2} size="middle">
                <Descriptions.Item label={t("battery.level")}>
                  {taggifyString(batteryInformation?.charge)}
                </Descriptions.Item>
                <Descriptions.Item label={t("battery.temp")}>
                  {taggifyString(batteryInformation?.temperature)}
                </Descriptions.Item>
                <Descriptions.Item label={t("battery.state")}>
                  {taggifyString(batteryInformation?.chargingState)}
                </Descriptions.Item>
                <Descriptions.Item label={t("battery.cycle")}>
                  {taggifyString(batteryInformation?.cycleCount)}
                </Descriptions.Item>
                <Descriptions.Item label={t("battery.health")}>
                  {taggifyString(batteryInformation?.health)}
                </Descriptions.Item>
                <Descriptions.Item label={t("battery.current-max")}>
                  {batteryInformation?.currentMaxCapacity != null && (
                    <>
                      {/* eslint-disable-next-line react/jsx-no-literals */}
                      {taggifyString(batteryInformation.currentMaxCapacity)}mAh (
                      {batteryInformation.capacity != null &&
                        taggifyString(batteryInformation.capacity, false, formatBatteryCapacity)}
                      )
                    </>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Collapse>
              <Panel header={t("device-parts")} key="1">
                <Table
                  key={process?.sessionIDHumanReadable}
                  dataSource={partsCheck}
                  columns={[
                    {
                      title: t("device-part.part"),
                      dataIndex: "name",
                    },
                    summaryDisplayProgressBar(),
                    {
                      title: t("device-part.original"),
                      dataIndex: "result",
                      render: (_, { result }) => {
                        const upperCaseResult = result?.toUpperCase();
                        const color =
                          statusColorMapping[upperCaseResult as keyof typeof statusColorMapping] ||
                          "#f8ac1c";
                        return <b style={{ color }}>{upperCaseResult}</b>;
                      },
                    },
                    {
                      title: t("device-part.current"),
                      dataIndex: "currentSerial",
                      render: (_, { currentSerial }) => taggifyString(currentSerial),
                    },
                    {
                      title: t("device-part.factory"),
                      dataIndex: "factorySerial",
                      render: (_, { factorySerial }) => taggifyString(factorySerial),
                    },
                    {
                      title: t("device-part.message"),
                      dataIndex: "message",
                      render: (_, { message }) => taggifyString(message),
                    },
                  ]}
                  rowKey="name"
                  pagination={false}
                />
              </Panel>
            </Collapse>
            <Card title={t("services")} bordered={false}>
              <Table
                key={process?.sessionIDHumanReadable}
                dataSource={services}
                columns={[
                  {
                    title: t("service.name"),
                    dataIndex: "name",
                    render: (_, { name }) => taggifyString(name, true),
                  },
                  summaryDisplayProgressBar(),
                  {
                    title: t("service.status"),
                    dataIndex: "status",
                    render: (_, { status }) => {
                      const color =
                        statusColorMapping[status as keyof typeof statusColorMapping] || "#f8ac1c";
                      return <b style={{ color }}>{status}</b>;
                    },
                  },
                  createDateColumnConfig(t("service.start")),
                  {
                    title: t("service.duration"),
                    dataIndex: "duration",
                    render: (_, { duration, durationUnits }) =>
                      duration != null && durationUnits != null
                        ? renderDuration(duration, durationUnits)
                        : null,
                  },
                ]}
                rowKey="name"
                showSorterTooltip
                pagination={false}
              />
            </Card>
            <Collapse>
              <Panel header={t("additional-info")} key="1">
                <Descriptions title={t("software-info")} bordered column={3} size="middle">
                  <Descriptions.Item label={t("dse")}>
                    {taggifyString(softwareInformation?.dseVersion)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("pro-d-ios")}>
                    {taggifyString(softwareInformation?.iosDiagnosticsVersion)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("pro-d-android")}>
                    {taggifyString(softwareInformation?.androidDiagnosticsVersion)}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header={t("advanced-info")} key="1">
                <Descriptions title={t("config")} bordered column={2} size="middle">
                  <Descriptions.Item label={t("facility")}>
                    {taggifyString(processRun?.facilityName)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("deployment")}>
                    {taggifyString(processRun?.deploymentName)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("program")}>
                    {taggifyString(configurationInformation?.program)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("service-suite")}>
                    {taggifyString(configurationInformation?.serviceSuite)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("test-suite")}>
                    {taggifyString(configurationInformation?.testSuite)}
                  </Descriptions.Item>
                </Descriptions>

                <Descriptions title={t("desktop-info")} bordered column={2} size="middle">
                  <Descriptions.Item label={t("desktop.name")}>
                    {taggifyString(desktopInformation?.computerName)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("desktop.ip")}>
                    {taggifyString(inputOrigin?.ip)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("desktop.ip-country")}>
                    {inputOrigin?.requestHeader?.["Cf-Ipcountry"] ?? "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("desktop.manufacturer")}>
                    {taggifyString(desktopInformation?.manufacturer)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("desktop.model")}>
                    {taggifyString(desktopInformation?.model)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("desktop.os-architecture")}>
                    {taggifyString(desktopInformation?.osArchitecture)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("desktop.os-name")}>
                    {taggifyString(desktopInformation?.osName)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("desktop.os-version")}>
                    {taggifyString(desktopInformation?.osVersion)}
                  </Descriptions.Item>
                </Descriptions>
                <Button
                  type="primary"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(processRun, null, "\t")
                  )}`}
                  download={`${
                    process?.sessionIDHumanReadable != null
                      ? process.sessionIDHumanReadable
                      : "process-run"
                  }.json`}
                  style={{ marginTop: "1em" }}
                >
                  {t("download-json")}
                </Button>
              </Panel>
            </Collapse>
          </Space>
        </Col>
      </Row>
    </Col>
  );
};
