// inventoryTasksSlice.ts

import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { InventoryManagement } from "~/services";
import { RootState } from "~/store";

export type InventoryTask = {
  task_id: string;
  description: string;
  status: string;
  user: string;
  start_time: string;
  end_time: string;
};

type ServiceError = { json?: { message?: string } };

export const fetchInventoryTasks = createAsyncThunk<InventoryTask[], void, { rejectValue: string }>(
  "inventoryTasks/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = (await InventoryManagement.GET("/inventory/tasks")) as {
        payload: InventoryTask[];
      };
      return response.payload;
    } catch (err) {
      const message = ((err as ServiceError).json?.message ?? "") || "Request failed";
      return rejectWithValue(message);
    }
  }
);

export const reimportInventoryLocations = createAsyncThunk<void, void, { rejectValue: string }>(
  "inventoryTasks/reimportLocations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await InventoryManagement.GET("/inventory/locations/reimport");

      if (response.success !== true) {
        const errorMessage =
          typeof response.message === "string" ? response.message : "Reimport locations failed";
        throw new Error(errorMessage);
      }
      return;
    } catch (err) {
      const message =
        ((err as ServiceError).json?.message as string) ||
        (err instanceof Error ? err.message : "Request failed");
      rejectWithValue(message);
    }
  }
);

export const reimportInventorySKUs = createAsyncThunk<void, void, { rejectValue: string }>(
  "inventoryTasks/reimportSKUs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await InventoryManagement.GET("/inventory/inventory-skus/reimport");

      if (response.success !== true) {
        const errorMessage =
          typeof response.message === "string" ? response.message : "Reimport SKUs failed";
        throw new Error(errorMessage);
      }
      return;
    } catch (err) {
      const message =
        ((err as ServiceError).json?.message as string) ||
        (err instanceof Error ? err.message : "Request failed");
      rejectWithValue(message);
    }
  }
);

const inventoryTasksAdapter = createEntityAdapter<InventoryTask>({
  selectId: (task) => task.task_id,
  sortComparer: (a, b) => new Date(b.start_time).getTime() - new Date(a.start_time).getTime(),
});

interface InventoryTasksState extends EntityState<InventoryTask> {
  status: "idle" | "loading";
  reimportStatus: {
    locations: "idle" | "loading" | "succeeded" | "failed";
    skus: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
  };
}

const initialState: InventoryTasksState = {
  ...inventoryTasksAdapter.getInitialState(),
  status: "idle",
  reimportStatus: {
    locations: "idle",
    skus: "idle",
    error: null,
  },
};

const inventoryTasksSlice = createSlice({
  name: "inventoryTasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryTasks.pending, (draft) => {
        draft.status = "loading";
      })
      .addCase(fetchInventoryTasks.fulfilled, (draft, action) => {
        draft.status = "idle";
        inventoryTasksAdapter.setAll(draft, action.payload);
      })
      .addCase(fetchInventoryTasks.rejected, (draft) => {
        draft.status = "idle";
      });

    builder
      .addCase(reimportInventoryLocations.pending, (draft) => {
        draft.reimportStatus.locations = "loading";
        draft.reimportStatus.error = null;
      })
      .addCase(reimportInventoryLocations.fulfilled, (draft) => {
        draft.reimportStatus.locations = "succeeded";
      })
      .addCase(reimportInventoryLocations.rejected, (draft, action) => {
        draft.reimportStatus.locations = "failed";
        draft.reimportStatus.error = action.payload || "Failed to reimport locations";
      });

    builder
      .addCase(reimportInventorySKUs.pending, (draft) => {
        draft.reimportStatus.skus = "loading";
        draft.reimportStatus.error = null;
      })
      .addCase(reimportInventorySKUs.fulfilled, (draft) => {
        draft.reimportStatus.skus = "succeeded";
      })
      .addCase(reimportInventorySKUs.rejected, (draft, action) => {
        draft.reimportStatus.skus = "failed";
        draft.reimportStatus.error = action.payload || "Failed to reimport SKUs";
      });
  },
});

export const inventoryTasksSelectors = inventoryTasksAdapter.getSelectors<RootState>(
  (state) => state.inventoryTasks
);

export default inventoryTasksSlice.reducer;
