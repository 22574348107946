import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Timestamp from "~/workstation/components/common/Timestamp";
import ReduxerControlled from "~/global/reduxer/components/ReduxerControlled";
import {
  selectors as workstationSelectors,
  actions as workstationActions,
} from "~/workstation/redux";
import AutoFilter from "~/global/reduxer/components/AutoFilter";

const IssueList = (props) => {
  const { workstationId, sessionId } = props;
  const issues = useSelector(workstationSelectors.issues.getFilteredResults);
  const column = [
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (createdAt) => <Timestamp value={createdAt} />,
    },
    {
      title: "Session	",
      dataIndex: "sessionId",
      render: (sessionId) => <Link to={`${sessionId}`}>{sessionId}</Link>,
    },
    {
      title: () => (
        <AutoFilter
          actions={workstationActions.issues}
          selectors={workstationSelectors.issues}
          identifier="reason"
          style={{ width: "12rem" }}
        />
      ),
      dataIndex: "reason",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "LogFile",
      dataIndex: "logFileUrl",
      render: (logFileUrl) => (
        <a href={logFileUrl} target="_blank" rel="noopener noreferrer">
          Download logs
        </a>
      ),
    },
  ];

  return (
    <ReduxerControlled
      selectors={workstationSelectors.issues}
      actions={workstationActions.issues}
      fetchArguments={[{ workstationId, sessionId }]}
    >
      <Table columns={column} dataSource={issues} pagination={false} />
    </ReduxerControlled>
  );
};

IssueList.defaultProps = {};

IssueList.propTypes = {
  workstationId: PropTypes.string,
  sessionId: PropTypes.string,
};

export default IssueList;
