import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import uuid from "uuid/v4";
import { Button } from "react-md";
import { message } from "antd";
import { Row, AsciButton, Checkbox, DialogMessage, SectionHeader } from "~/global";

import UploadInfoFilters from "../UploadInfoFilters/UploadInfoFilters.component";
import ManualEntry from "../ManualEntry/ManualEntry.component";
import IdentifyingInfo from "../IdentifyingInfo/IdentifyingInfo.component";
import DeviceInfo from "../DeviceInfo/DeviceInfo.component";
import TestInfo from "../TestInfo/TestInfo.component";
import CustomFieldsSection from "../CustomFieldsSection/CustomFieldsSection.component";

import actions from "~/actions";
import moduleActions from "~/data-import/actions/";

import "./ManualUploadPage.component.scss";

const ManualUploadPage = ({
  bluetoothMAC,
  createRecording,
  duration,
  csid,
  customFields,
  deviceData,
  emptyFields,
  failCode,
  imei,
  meid,
  username,
  OEMSerialNumber,
  operator,
  processDate,
  processResult,
  saveSessionId,
  selectedFilters,
  sessionField,
  wifiMAC,
  showDialog,
  isIdentifyingInfoValid,
}) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const [resetFilters, setResetFilters] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [showSessionId, setShowSessionId] = useState(false);
  const [processAnother, setProcessAnother] = useState(false);
  const [isImportAllowed, setIsImportAllowed] = useState(false);

  const isFacilitySelected = () => {
    let isSelected = false;

    if (selectedFilters !== undefined) {
      for (const field of Object.values(selectedFilters)) {
        if (field !== undefined) {
          isSelected = field.faid != "all";
          if (!isSelected) {
            break;
          }
        }
      }
    }

    return isSelected;
  };

  useEffect(() => {
    const id = uuid();
    setSessionId(id);
  }, []);

  useEffect(() => {
    saveSessionId(sessionId);
  }, [sessionId]);

  useEffect(() => {
    let filtersOk = false;
    let identifyingDataOk = false;
    let manualEntryOk = false;
    let deviceInfoOk = false;
    let testInfoOk = false;

    if (
      imei !== "" ||
      meid !== "" ||
      wifiMAC !== "" ||
      OEMSerialNumber !== "" ||
      bluetoothMAC !== ""
    ) {
      identifyingDataOk = true;
    } else {
      identifyingDataOk = false;
    }

    if (
      selectedFilters.shift !== undefined &&
      selectedFilters.team !== undefined &&
      selectedFilters.config !== undefined
    ) {
      if (
        selectedFilters.shift.id !== "all" &&
        selectedFilters.team.teamid !== "all" &&
        selectedFilters.config.id !== "all"
      ) {
        filtersOk = true;
      } else {
        filtersOk = false;
      }
    }

    if (deviceData.manufacturer === "Apple" || deviceData.manufacturer === "apple") {
      if (
        deviceData.modelName !== "" &&
        deviceData.modelNumber !== "" &&
        deviceData.color !== "" &&
        deviceData.capacity !== ""
      ) {
        deviceInfoOk = true;
      } else {
        deviceInfoOk = false;
      }
    } else if (
      deviceData.modelName !== "" &&
      deviceData.color !== "" &&
      deviceData.capacity !== "" &&
      deviceData.manufacturer !== ""
    ) {
      deviceInfoOk = true;
    } else {
      deviceInfoOk = false;
    }

    if (processResult !== "") {
      if (processResult === "PASS") {
        testInfoOk = true;
      } else if (processResult === "FAIL") {
        if (failCode.description !== "n/a" && failCode.description !== "") {
          testInfoOk = true;
        } else {
          testInfoOk = false;
        }
      }
    } else {
      testInfoOk = false;
    }

    if (operator !== "") {
      manualEntryOk = true;
    } else {
      manualEntryOk = false;
    }

    if (testInfoOk && filtersOk && identifyingDataOk && manualEntryOk && deviceInfoOk) {
      setIsImportAllowed(true);
    } else {
      setIsImportAllowed(false);
    }
  }, [
    selectedFilters,
    imei,
    meid,
    wifiMAC,
    OEMSerialNumber,
    bluetoothMAC,
    operator,
    processResult,
    sessionField,
    customFields,
    failCode,
    deviceData,
  ]);

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = sessionId;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    message.info(`${sessionId} copied to clipboard`);
  };

  const buildUploadData = () => {
    const recording = {
      bluetoothMAC,
      csid,
      customFields,
      deviceData,
      failCode,
      duration,
      imei,
      meid,
      username,
      OEMSerialNumber,
      operator,
      processDate,
      processResult,
      selectedFilters,
      sessionId,
      wifiMAC,
    };

    clearFields();
    createRecording(recording);
    setShowSessionId(true);
    message.success("Record added to the database");
  };

  const clearFields = () => {
    emptyFields();
    if (!processAnother) {
      setResetFilters(true);
    }
  };

  const showInfoMessage = () => {
    showDialog({
      title: "Usage Information",
      width: "450px",
      modal: false,
      content: (close) => (
        <DialogMessage close={close}>
          <p>The manual entry allows you to import a single entry in the database.</p>
        </DialogMessage>
      ),
    });
  };

  return (
    <div className="manual-upload-tab">
      <SectionHeader className="row-reverse">
        <Button
          icon
          className="icon-btn text-color--white"
          iconClassName="icon-info-circle"
          forceIconSize={32}
          forceIconFontSize
          onClick={showInfoMessage}
        />
      </SectionHeader>
      <Row className="section-spacing">
        <UploadInfoFilters
          currentFilters={currentFilters}
          setCurrentFilters={(filters) => setCurrentFilters(filters)}
          resetFilters={resetFilters}
          setResetFilters={(reset) => setResetFilters(reset)}
        />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing">
        <ManualEntry />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing">
        <IdentifyingInfo />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing">
        <DeviceInfo />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing">
        <TestInfo />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing">
        <CustomFieldsSection />
      </Row>

      <div className="section-separator" />

      <Row className="section-spacing" align="space-between">
        {showSessionId ? (
          <div>
            <AsciButton className="session-id-btn" color="white" onClick={() => copyToClipboard()}>
              Copy session ID
              <i className="icon-copy" />
            </AsciButton>
          </div>
        ) : (
          <div />
        )}

        <Row className="button-group">
          <Checkbox
            key="process-another"
            id="process-another-checkbox"
            label="Process another"
            isChecked={processAnother}
            onChange={(value) => setProcessAnother(value)}
          />

          <AsciButton
            className="clear-filters-btn"
            color="white"
            onClick={() => setResetFilters(true)}
            disabled={!isFacilitySelected()}
          >
            Clear filters
          </AsciButton>

          <AsciButton
            className="import-data-btn"
            color="blue"
            onClick={() => buildUploadData()}
            disabled={!isImportAllowed || !isIdentifyingInfoValid}
          >
            Import into Database
          </AsciButton>
        </Row>
      </Row>
    </div>
  );
};

const stateToProps = (state) => ({
  bluetoothMAC: state.modules.dataImport.importDataManual.bluetoothMAC,
  csid: state.customer.activeCustomerId,
  customFields: state.modules.dataImport.importDataManual.customFields,
  deviceData: state.modules.dataImport.importDataManual.deviceData,
  failCode: state.modules.dataImport.importDataManual.failCode,
  imei: state.modules.dataImport.importDataManual.imei,
  meid: state.modules.dataImport.importDataManual.meid,
  OEMSerialNumber: state.modules.dataImport.importDataManual.OEMSerialNumber,
  operator: state.modules.dataImport.importDataManual.operator,
  processDate: state.modules.dataImport.importDataManual.processDate,
  processResult: state.modules.dataImport.importDataManual.processResult,
  selectedFilters: state.modules.dataImport.importDataAutomatic.selectedFilters,
  username: state.session.user.nickname,
  wifiMAC: state.modules.dataImport.importDataManual.wifiMAC,
  duration: state.modules.dataImport.importDataManual.duration,
  isIdentifyingInfoValid: state.modules.dataImport.importDataManual.isIdentifyingInfoValid,
});

const dispatchToProps = {
  saveSessionId: moduleActions.importDataManual.saveSessionId,
  createRecording: moduleActions.importDataManual.createRecording,
  emptyFields: moduleActions.importDataManual.emptyFields,
  showDialog: actions.dialog.show,
};

export default connect(stateToProps, dispatchToProps)(ManualUploadPage);
