import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BarChartOutlined,
  ExportOutlined,
  HistoryOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button as AntButton, Modal, Select } from "antd";
import { format } from "date-fns";
import { differenceWith, isEqual, isNil, omit, omitBy, partition } from "lodash";
import { Button } from "react-md";
import { useLocation, useSearchParams } from "react-router-dom-v5-compat";

import actions from "~/actions";
import { ShortFacility } from "~/actions/facilities.actions";
import { ReportType } from "~/actions/reportTypes.actions";
import { DataExport, DialogMessage, Row } from "~/global";
import { ExportedReportFilters } from "~/actions/report.actions";
import { ChartReportTypeName, ReportTypeName } from "~/actions/reportTypes.actions";
import { CsvFile, fileCleared } from "~/features/csv-upload/csvUploadSlice";
import {
  facetsToApiFilters,
  SelectedFacet,
} from "~/features/recording-facets/RecordingFacetsFilter.component";
import { FacetOption } from "~/features/recording-facets/reportingFilters.slice";
import ReportingChart from "~/features/reporting-chart/ReportingChart.component";
import { selectTotalCount } from "~/features/reporting-chart/reportingChart.slice";
import {
  DateRangeKeys,
  getInitialFilters,
  ReportingFilters,
  ReportingFiltersType,
} from "~/features/reporting-filters";
import { relativeDateRanges } from "~/global/DateTimeSelector/DatePicker/DatePicker.component";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTranslation } from "~/i18n";
import { ReportTypesByName } from "~/selectors/reports.selectors";
import { ProcessRuns } from "./ProcessRuns";
import { REPORTING_SEARCH_PARAMS_VERSION } from "./model/constants";
import { ReportingSection } from "./ReportingSection";

import "./ReportingFiltersAndChart.scss";

export interface ChartFilters extends ReportingFiltersType {
  csvFile?: CsvFile;
  reportTypeName: ChartReportTypeName;
}

export interface SearchFilters extends ReportingFiltersType {
  csvFile?: CsvFile;
  reportTypeName: ReportTypeName;
  columns?: string;
}

type Props = {
  reportTypes: ReportType[];
  reportTypesByName: ReportTypesByName;
  facilities: ShortFacility[];
  facetOptions: FacetOption[];
};

const { Option } = Select;

export const ReportingFiltersAndChart = ({
  reportTypes,
  reportTypesByName,
  facilities,
  facetOptions,
}: Props) => {
  const { t } = useTranslation("reporting");
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const totalCount = useAppSelector(selectTotalCount);
  // TODO: move this to the ReportingFilters (soon to be AnalyticsFilters)
  const csvFile = useAppSelector((state) => state.reportingModule.csvUpload.file);
  const loading = useAppSelector((state) => state.reportingModule.report.status === "loading");

  const [chosenReportType, setChosenReportType] = useState<ReportType>(
    reportTypesByName[searchParams.get("reportTypeName") ?? ""] ??
      reportTypes.find((reportType) => reportType.isDefault) ??
      reportTypes[0]
  );
  const [reportingFilters, setReportingFilters] = useState(getInitialFilters(facilities));
  const [chartFilters, setChartFilters] = useState<ChartFilters>();
  const [searchFilters, setSearchFilters] = useState<SearchFilters>();
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [clearFacets, setClearFacets] = useState(false);

  const chartReportName = reportTypesByName[chartFilters?.reportTypeName ?? ""]?.displayName;
  const searchReportName = reportTypesByName[searchFilters?.reportTypeName ?? ""]?.displayName;

  const deserializeFilters = useCallback(
    (searchParams: URLSearchParams): ReportingFiltersType => {
      const isFacet = (key: string) => /^facet\./.test(key);

      const facets = Array.from(searchParams.entries())
        .filter(([key]) => isFacet(key))
        .map(([key, value]) => [key.slice(6), value]);

      const dateRange = searchParams.get("dateRange");
      const range =
        dateRange != null && dateRange in relativeDateRanges
          ? relativeDateRanges[dateRange as DateRangeKeys]
          : undefined;

      const dateParams = range
        ? {
            dateRange: range.value as DateRangeKeys,
            dateFrom: format(range.range().startDate, "yyyy-MM-dd'T'HH:mm:ss"),
            dateTo: format(range.range().endDate, "yyyy-MM-dd'T'HH:mm:ss"),
          }
        : undefined;

      const paramsWithoutFacets = omitBy(
        omit(Object.fromEntries(searchParams.entries()), ["reportTypeName", "v"]),
        (value, key) => isNil(value) || isFacet(key)
      );

      const filters = {
        ...getInitialFilters(facilities),
        ...paramsWithoutFacets,
        ...dateParams,
        facets,
      };

      return filters;
    },
    [facilities]
  );

  const reportingFiltersRef = useRef(reportingFilters);
  const chosenReportTypeRef = useRef(chosenReportType);

  // This useEffect reads the search params and sets them to the appropriate states (reportingFilters and chosenReportType)
  useEffect(() => {
    const filters = deserializeFilters(searchParams);

    reportingFiltersRef.current = filters;
    setReportingFilters(filters);

    if (searchParams.has("reportTypeName")) {
      const reportType =
        reportTypesByName[searchParams.get("reportTypeName") as ReportTypeName] ??
        reportTypes.find((reportType) => reportType.isDefault) ??
        reportTypes[0];

      chosenReportTypeRef.current = reportType;
      setChosenReportType(reportType);
    }
  }, [deserializeFilters, reportTypes, reportTypesByName, searchParams]);

  // This useEffect handles the automatic trigger of the search
  useEffect(() => {
    const locationState = state as { triggerSearch?: boolean } | undefined;

    if (locationState?.triggerSearch ?? false) {
      const filters = {
        ...reportingFiltersRef.current,
        reportTypeName: chosenReportTypeRef.current.name,
      };

      setSearchFilters(filters);
    }
  }, [state]);

  useEffect(() => {
    // This code will run whenever chosenReportType changes
    setClearFacets(true);
  }, [chosenReportType]);

  const serializeFilters = (filters: ExportedReportFilters): Record<string, string | string[]> => {
    const { facets, dateFrom, dateTo, dateRange, uniqueness, ...rest } = filters;

    const facetFilters = Object.fromEntries(
      facetsToApiFilters(facets).map((facet) => [`facet.${facet.field}`, facet.value])
    );

    const dateFilters:
      | { dateRange: string }
      | { dateRange: string; dateFrom: string; dateTo: string } =
      dateRange !== "custom" ? { dateRange } : { dateRange, dateFrom, dateTo };

    const otherFilters = omitBy(
      omit(
        { ...rest, uniqueness: chosenReportType.uniquenessSupport ? uniqueness : undefined },
        "csvFile"
      ),
      isNil
    );

    const searchParams = {
      ...facetFilters,
      ...dateFilters,
      ...otherFilters,
      v: REPORTING_SEARCH_PARAMS_VERSION,
    };

    return searchParams;
  };

  const handleSearch = () => {
    const filters = {
      ...reportingFilters,
      reportTypeName: chosenReportType.name,
      csvFile,
    };

    setSearchFilters(filters);
    setSearchParams(serializeFilters(filters));
  };

  const exportReport = async (fileFormat: string, columns?: string[], email?: string) => {
    const partialFilters = { reportTypeName: chosenReportType.name, csvFile };
    const filters = chosenReportType.uniquenessSupport
      ? { ...reportingFilters, ...partialFilters }
      : { ...omit(reportingFilters, "uniqueness"), ...partialFilters };

    const { error } = await dispatch(
      actions.report.generateReport({
        exportedReportFilters: filters,
        fileFormat,
        columns,
        email,
      })
    );

    // TODO: use antd notification instead of dialog
    if (error != null || email != null) {
      dispatch(
        actions.dialog.show({
          title: t("information"),
          width: "800px",
          content: (close: any) => (
            <DialogMessage close={close}>
              {error != null ? (
                // TODO: translate the error message (GLOB-3301)
                <div>{error}</div>
              ) : (
                <div>{t("email-will-be-sent", { email })}</div>
              )}
            </DialogMessage>
          ),
        })
      );
    }

    void dispatch(actions.exportedReports.fetchExportedReports());
    setSearchParams(serializeFilters(filters));
    setIsExportModalVisible(false);
  };

  const generateChart = (filters: ChartFilters) => {
    setChartFilters(filters);
    setSearchParams(serializeFilters(filters));
  };

  const drillDown = (facets: SelectedFacet[]) => {
    const selectedFacets = reportingFilters.facets;

    // All facets should have the same field
    const [affectedField, _] = facets[0];
    const [affectedFacets, otherFacets] = partition(
      selectedFacets,
      ([field, _]) => field === affectedField
    );

    const newFacets = [
      ...otherFacets,
      ...differenceWith(affectedFacets, facets, isEqual),
      ...differenceWith(facets, affectedFacets, isEqual),
    ];

    setReportingFilters((oldFilters) => ({
      ...oldFilters,
      facets: newFacets,
    }));
  };

  return (
    <div className="reporting-filters-and-chart">
      {/* TODO: use the SelectWithLabel component */}
      <Row
        align="start center"
        style={{
          // TODO: temp fix until we update the styles
          marginBottom: "1em",
        }}
      >
        <div className="select-label">{t("report")}</div>

        <Select
          value={chosenReportType.name}
          onChange={(reportTypeName) => {
            setChosenReportType(reportTypesByName[reportTypeName]);
          }}
          style={{
            width: 200,
            // TODO: temp fix until we update the styles
            marginLeft: "0.5em",
          }}
          aria-label="select-report"
        >
          {reportTypes.map(({ name, displayName, chartSupport, searchSupport }) => (
            <Option key={name} value={name} data-testid={name}>
              <Row align="space-between center">
                {t("common:multi", { dict: displayName })}

                {(chartSupport || searchSupport) && (
                  <span className="report-icons">
                    {searchSupport && <HistoryOutlined />}
                    {chartSupport && <BarChartOutlined />}
                  </span>
                )}
              </Row>
            </Option>
          ))}
        </Select>
      </Row>

      <ReportingFilters
        facilities={facilities}
        facetOptions={facetOptions}
        reportingFilters={reportingFilters}
        setReportingFilters={setReportingFilters}
        csvSupport={chosenReportType.csvSupport}
        searchSupport={chosenReportType.searchSupport}
        onSearch={handleSearch}
        excludeUniqueness={!chosenReportType.uniquenessSupport}
        currentReportType={chosenReportType.name}
        clearFacets={clearFacets}
        setClearFacets={setClearFacets}
      />

      <Row
        className="buttons"
        align="end"
        style={{
          // TODO: temp fix until we update the styles
          margin: "1em 0",
        }}
      >
        <AntButton
          onClick={() => {
            setReportingFilters(getInitialFilters(facilities));
            setChartFilters(undefined);
            setSearchFilters(undefined);
            setSearchParams([]);
            dispatch(fileCleared());
          }}
        >
          {t("reset-filters")}
        </AntButton>

        {chosenReportType.searchSupport && (
          <AntButton icon={<SearchOutlined />} onClick={handleSearch}>
            {t("search")}
          </AntButton>
        )}

        {chosenReportType.chartSupport && (
          <AntButton
            icon={<BarChartOutlined />}
            onClick={() => {
              generateChart({
                ...reportingFilters,
                reportTypeName: chosenReportType.name,
                csvFile,
              });
            }}
          >
            {t("generate-chart")}
          </AntButton>
        )}

        <AntButton
          type="primary"
          icon={<ExportOutlined />}
          onClick={() => setIsExportModalVisible(true)}
        >
          {t("export-btn")}
        </AntButton>

        <Modal
          title={t("export-report")}
          open={isExportModalVisible}
          onCancel={() => setIsExportModalVisible(false)}
          footer={null}
          width={960}
        >
          {/* TODO: review this component */}
          <DataExport
            key={chosenReportType.name}
            onSend={(fileFormat: string, columns?: string[], email?: string) => {
              void exportReport(fileFormat, columns, email);
            }}
            onDownload={(fileFormat: string, columns?: string[]) => {
              void exportReport(fileFormat, columns);
            }}
            exportFormats={chosenReportType.exportFormats}
            exportColumns={chosenReportType.exportColumns}
            loading={loading}
          />
        </Modal>
      </Row>

      {searchFilters && (
        <ProcessRuns
          filters={searchFilters}
          extraColumns={chosenReportType.exportColumns}
          title={searchReportName}
          onClose={() => {
            setSearchFilters(undefined);
          }}
        />
      )}

      {chartFilters && (
        <ReportingSection
          title={t("chart-title", { chartReportName })}
          description={t("chart-description", {
            totalCount,
            chartReportName,
          })}
          headerExtraContent={
            <Button
              icon
              className="icon-btn"
              iconEl={
                <i
                  className="icon-x"
                  style={{ color: "#212121", fontSize: 30, fontWeight: "bold" }}
                />
              }
              onClick={() => {
                setChartFilters(undefined);
              }}
            />
          }
        >
          <ReportingChart
            key={chartFilters.reportTypeName}
            chartFilters={chartFilters}
            reportType={reportTypesByName[chartFilters.reportTypeName]}
            onDrillDown={drillDown}
            facilities={facilities}
            setReportingFilters={setReportingFilters}
          />
        </ReportingSection>
      )}
    </div>
  );
};
