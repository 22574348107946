import React, { useEffect } from "react";
import { Button, Card, message, Table, Tag } from "antd";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  fetchInventoryTasks,
  InventoryTask,
  inventoryTasksSelectors,
  reimportInventoryLocations,
  reimportInventorySKUs,
} from "../../../entities/inventory/model/inventoryTasksSlice";
import { useTranslation } from "~/i18n";

export const InventoryTasks: React.FC = () => {
  const { t } = useTranslation("inventory");
  const dispatch = useAppDispatch();

  const tasks = useAppSelector(inventoryTasksSelectors.selectAll);
  const tasksStatus = useAppSelector((state) => state.inventoryTasks.status);

  useEffect(() => {
    void dispatch(fetchInventoryTasks());
  }, [dispatch]);

  const handleReimportLocations = () => {
    dispatch(reimportInventoryLocations())
      .unwrap()
      .then(() => {
        void message.success(t("reimport-locations-success"));
        void dispatch(fetchInventoryTasks());
      })
      .catch((error) => {
        void message.error(t("reimport-locations-failed", { error }));
      });
  };

  const handleReimportSKUs = () => {
    dispatch(reimportInventorySKUs())
      .unwrap()
      .then(() => {
        void message.success(t("reimport-skus-success"));
        void dispatch(fetchInventoryTasks());
      })
      .catch((error) => {
        void message.error(t("reimport-skus-failed", { error }));
      });
  };

  return (
    <Card
      className="inventory-tasks"
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>{t("inventory-tasks")}</span>
          <div>
            <Button
              title={t("refresh-tasks")}
              type="text"
              icon={<ReloadOutlined />}
              onClick={() => dispatch(fetchInventoryTasks())}
            />
          </div>
        </div>
      }
      bordered={false}
      style={{ marginTop: 30 }}
    >
      <div style={{ marginBottom: 16 }}>
        <Button onClick={handleReimportLocations} style={{ marginRight: 8 }}>
          {t("update-locations")}
        </Button>
        <Button onClick={handleReimportSKUs}>{t("update-skus")}</Button>
      </div>

      <Table
        columns={[
          {
            title: t("task-id"),
            dataIndex: "task_id",
            key: "task_id",
            sorter: (a, b) => a.task_id.localeCompare(b.task_id),
          },
          {
            title: t("description"),
            dataIndex: "description",
            key: "description",
            sorter: (a, b) => a.description.localeCompare(b.description),
          },
          {
            title: t("user"),
            dataIndex: "user",
            key: "user",
            sorter: (a, b) => a.user.localeCompare(b.user),
          },
          {
            title: t("status"),
            dataIndex: "status",
            key: "status",
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (status: string) => {
              let color: string;
              switch (status) {
                case "success":
                  color = "green";
                  break;
                case "started":
                  color = "blue";
                  break;
                case "fail":
                  color = "red";
                  break;
                default:
                  color = "volcano";
              }
              return <Tag color={color}>{status}</Tag>;
            },
          },
          {
            title: t("start-time"),
            dataIndex: "start_time",
            key: "start_time",
            sorter: (a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime(),
            render: (start_time: string) => new Date(start_time).toLocaleString(),
          },
          {
            title: t("end-time"),
            dataIndex: "end_time",
            key: "end_time",
            sorter: (a, b) =>
              new Date(a.end_time || 0).getTime() - new Date(b.end_time || 0).getTime(),
            render: (end_time: string | undefined, record: InventoryTask) => {
              if (record.status === "started") {
                return "N/A";
              }
              if (end_time == null) {
                return t("in-progress");
              }
              const parsedDate = new Date(end_time);
              if (isNaN(parsedDate.getTime())) {
                return t("invalid-date");
              }
              return parsedDate.toLocaleString();
            },
          },
        ]}
        dataSource={tasks}
        rowKey="task_id"
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
        }}
        loading={
          tasksStatus === "loading"
            ? {
                indicator: <LoadingOutlined style={{ color: "#005288" }} />,
                size: "large",
              }
            : false
        }
      />
    </Card>
  );
};
