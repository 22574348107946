import React, { useEffect, useState } from "react";
import type { KeyboardEvent } from "react";

import { TextField } from "react-md";

import { Transfer, Radio, RadioChangeEvent, Select, Switch } from "antd";
import type { TransferItem } from "antd/lib/transfer";
import camelCase from "lodash/camelCase";
import { SectionHeader, AsciButton } from "~/global";

import { ServiceSuiteServicesList } from "~/programs-management";
import WorkflowEditor from "~/workflow/components/editors/WorkflowEditor";

/**
 * TODO: A lot of these type definitions probably belong somewhere else.
 * A better way to organize reusable data types will be necessary.
 * Maybe whenever the folder structure of the project is reviewed.
 */

type Lang = "en" | "es" | "fr";

type ServiceArgumentInteger = {
  name: string;
  type: "integer";
  value: number;
};

type ServiceArgumentString = {
  name: string;
  type: "string";
  value: string;
};

type ServiceArgumentBool = {
  name: string;
  type: "bool";
  value: boolean;
};

type ServiceArgument = ServiceArgumentBool | ServiceArgumentString | ServiceArgumentInteger;

type Service = {
  arguments: ServiceArgument[];
  className: string;
  displayName: Record<Lang, string>;
  identifier: string;
  isEnabled: boolean;
  isOptional: boolean;
};
interface PriorityGroup {
  csid: string;
  id: string;
  failCodeCategories: any;
  isDefault: boolean;
  name: Record<Lang, string>;
}

type ServiceSuite = {
  createdOn?: string;
  description?: string;
  displayName: Record<Lang, string>;
  identifier: string;
  labelCopies?: number;
  labelType?: string;
  minimumIdentifiers?: string[];
  printLabel?: boolean;
  printOnResults?: unknown[];
  requiredFields?: string[];
  customPermissions?: string[];
  services?: Service[];
  servicesTemplateIdentifier?: string;
  workflow: unknown;
  pgid: string;
  processName?: string;
  processFlow?: string;
  isRmaMode?: boolean;
  sendToNetsuite?: boolean;
};

interface Identifiers extends TransferItem {
  canBeMinimumIdentifier: boolean;
  canBeRequiredIdentifier: boolean;
  displayName: Record<Lang, string>;
  name: string;
}

interface CustomPermissions extends TransferItem {
  displayName: Record<Lang, string>;
  name: string;
}

type TestSuite = {
  name: string;
  tsid: string;
};

const ServiceSuiteEditor = (props: {
  minimumIdentifiers: Identifiers[];
  requiredIdentifiers: Identifiers[];
  customPermissions: CustomPermissions[];
  diagnosticDeviceArgumentsByName: {
    testSuiteAndroid: {
      name: string;
      type: string;
      value: string;
    };
    testSuiteIOS: {
      name: string;
      type: string;
      value: string;
    };
  };
  identifiers: string[];
  names: Record<Lang, { name?: string; value: string }>;
  onAdd: (serviceSuite: ServiceSuite) => void;
  onClearEdition: () => void;
  onOrderChanged: () => void;
  onSave: (serviceSuite: ServiceSuite) => void;
  onServiceArgumentChanged: (...args: unknown[]) => unknown;
  onServiceToggled: (...args: unknown[]) => unknown;
  onTestSuiteChanged: (...args: unknown[]) => unknown;
  saveDisabled: boolean;
  serviceSuite: ServiceSuite;
  serviceSuiteDidChange: boolean;
  serviceSuiteRequireFilled: boolean;
  testSuitesAndroid: Record<string, TestSuite>;
  testSuitesIos: Record<string, TestSuite>;
  priorityGroups: PriorityGroup[];
  updateServiceSuite: (serviceSuite: Partial<ServiceSuite>) => void;
  fetchGroupPriorities: any;
  processNames: string[];
  processFlows: string[];
}) => {
  const [identifiers, setIdentifiers] = useState<string[]>([]);
  const [errorIdentifier, setErrorIdentifier] = useState(false);
  const [pgId, setPgId] = useState<string>();
  const [advancedMode, setAdvancedMode] = useState(false); // not MVP
  const isEditing = !!props.serviceSuite.createdOn;
  const priorityGroups = Object.values(props.priorityGroups);
  const { fetchGroupPriorities, serviceSuite, updateServiceSuite } = props;
  useEffect(
    () => () => {
      props.onClearEdition();
    },
    []
  );

  useEffect(() => {
    if (props.identifiers.length > 0) {
      const identifiersInUse = props.identifiers.filter(
        (identifier) => identifier != props.serviceSuite.identifier
      );
      setIdentifiers(identifiersInUse);
    }
  }, [props.identifiers]);

  useEffect(() => {
    fetchGroupPriorities().then((value: PriorityGroup[]) => {
      const servicePgId = value.find((e) => e.id === serviceSuite.pgid)?.id;
      const defaultPgId = value.find((e) => e.isDefault)?.id;
      const pgid = servicePgId ?? defaultPgId;
      setPgId(pgid);
      updateServiceSuite({ pgid });
    });
  }, [fetchGroupPriorities, serviceSuite.pgid, updateServiceSuite]);

  useEffect(() => {
    const currentIdentifier = camelCase(props.serviceSuite.identifier);
    const isValidIdentifier = identifiers.indexOf(currentIdentifier) < 0;
    setErrorIdentifier(!isValidIdentifier);
  }, [props.serviceSuite.identifier]);

  const onChangeIdentifier = (identifier: string) => {
    const serviceSuite = { identifier };
    props.updateServiceSuite(serviceSuite);
  };
  const onChangeGroups = (e: RadioChangeEvent) => {
    const pgid: string = e.target.value;
    setPgId(pgid);
    props.updateServiceSuite({ pgid });
  };
  const onChangeProcessName = (value: string) => {
    const processName: string = value;
    props.updateServiceSuite({ processName });
  };

  const onChangeProcessFlow = (value: string) => {
    const processFlow: string = value;
    props.updateServiceSuite({ processFlow });
  };
  const onChangeIsRmaMode = (value: boolean) => {
    const isRmaMode: boolean = value;
    props.updateServiceSuite({ isRmaMode });
  };

  const onChangeSendToNetsuite = (value: boolean) => {
    const sendToNetsuite: boolean = value;
    props.updateServiceSuite({ sendToNetsuite });
  };

  const blurIdentifier = () => {
    const identifier = camelCase(props.serviceSuite.identifier);
    const serviceSuite = { identifier };
    props.updateServiceSuite(serviceSuite);
  };

  const onChangeDisplayName = (lang: Lang, value: string) => {
    const displayName = {
      ...props.serviceSuite.displayName,
      [lang]: value,
    };
    const serviceSuite = { displayName };
    props.updateServiceSuite(serviceSuite);
  };

  const onChangeDescription = (description = "") => {
    const serviceSuite = { description };
    props.updateServiceSuite(serviceSuite);
  };

  const onTransferIdentifiers = (minimumIdentifiers: string[]) => {
    const serviceSuite = { minimumIdentifiers };
    props.updateServiceSuite(serviceSuite);
  };

  const onTransferRequiredFields = (requiredFields: string[]) => {
    const serviceSuite = { requiredFields };
    props.updateServiceSuite(serviceSuite);
  };

  const onTransferCustomPermissions = (customPermissions: string[]) => {
    const serviceSuite = { customPermissions };
    props.updateServiceSuite(serviceSuite);
  };

  return (
    <div className="service-suite-editor form-layout">
      <SectionHeader
        title={
          props.serviceSuite.identifier.length > 0 ? "Edit service suite" : "New service suite"
        }
      />
      <div className="field-row">
        <div className="field-name">
          Service suite identifier *<div className="description">Letters and numbers only</div>
        </div>
        <div className="field-control">
          <TextField
            id="service-suite-identifier"
            lineDirection="left"
            placeholder="Enter service suite identifier"
            value={props.serviceSuite.identifier}
            // @ts-ignore
            onChange={(newIdentifier) => onChangeIdentifier(newIdentifier)}
            onBlur={() => blurIdentifier()}
            disabled={isEditing}
            error={errorIdentifier}
            errorText={`Service suite identifier "${props.serviceSuite.identifier}" already taken (intervening spaces removed).`}
            type="text"
            // @ts-ignore
            onKeyPress={(e: KeyboardEvent) => {
              if (!props.saveDisabled && !errorIdentifier && e.key == "Enter") {
                props.onSave(props.serviceSuite);
              }
            }}
          />
        </div>
      </div>
      {(Object.keys(props.names || {}) as Lang[]).map((lang) => {
        const language = props.names[lang].name != null ? props.names[lang].name : "";
        const optionalLanguage = lang != "en" ? "(optional)" : " *";
        const displayLanguage = `(${language}) ${optionalLanguage}`;

        return (
          <div className="field-row" key={lang}>
            <div className="field-name">Name {displayLanguage}</div>
            <div className="field-control">
              <TextField
                id={`service-suite-display-name-${lang}`}
                placeholder="Enter a display name"
                value={props.names[lang].value}
                // @ts-ignore
                onChange={(value) => onChangeDisplayName(lang, value)}
                type="text"
                // @ts-ignore
                onKeyPress={(e: KeyboardEvent) => {
                  if (!props.saveDisabled && !errorIdentifier && e.key == "Enter") {
                    props.onSave(props.serviceSuite);
                  }
                }}
              />
            </div>
          </div>
        );
      })}
      <div className="field-row">
        <div className="field-name">Description</div>
        <div className="field-control">
          <TextField
            id="service-suite-description"
            lineDirection="left"
            placeholder="Describe what your service suite does"
            value={props.serviceSuite.description}
            // @ts-ignore
            onChange={(newDescription) => onChangeDescription(newDescription)}
            type="text"
            // @ts-ignore
            onKeyPress={(e: KeyboardEvent) => {
              if (!props.saveDisabled && !errorIdentifier && e.key == "Enter") {
                props.onSave(props.serviceSuite);
              }
            }}
          />
        </div>
      </div>
      <div className="field-row">
        <div className="field-name">Priority group to use:</div>
        <div className="field-control">
          {priorityGroups.length > 0 && (
            <Radio.Group onChange={(e) => onChangeGroups(e)} value={pgId}>
              {priorityGroups.map((group: PriorityGroup) => (
                <Radio value={group.id} key={group.id}>
                  {group.name.en}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </div>
      </div>
      <div className="field-row">
        <div className="field-name">Process name:</div>
        <div className="field-control">
          <Select
            value={props.serviceSuite.processName}
            style={{ width: 120 }}
            onChange={(value) => onChangeProcessName(value)}
            options={props.processNames.map((name) => ({
              label: name,
              value: name,
            }))}
          />
        </div>
      </div>
      <div className="field-row">
        <div className="field-name">Process flow:</div>
        <div className="field-control">
          <Select
            value={props.serviceSuite.processFlow}
            style={{ width: 120 }}
            onChange={(value) => onChangeProcessFlow(value)}
            options={props.processFlows.map((name) => ({
              label: name,
              value: name,
            }))}
          />
        </div>
      </div>
      <div className="field-row">
        <div className="field-name">RMA mode:</div>
        <div className="field-control">
          <Switch
            checked={props.serviceSuite?.isRmaMode ?? false}
            onChange={(value) => onChangeIsRmaMode(value)}
          />
        </div>
      </div>
      <div className="field-row">
        <div className="field-name">Send to Netsuite</div>
        <div className="field-control">
          <Switch
            checked={props.serviceSuite?.sendToNetsuite ?? true}
            onChange={(value) => onChangeSendToNetsuite(value)}
          />
        </div>
      </div>
      <SectionHeader title="Services">
        {/* <div className="row centered">
                    <span style={{ marginRight: "10px" }}>ADVANCED MODE</span>
                    <Switch
                        id={"advanced-mode-services"}
                        type="switch"
                        name="advanced-mode-services"
                        checked={advancedMode}
                        label=""
                        onChange={() => setAdvancedMode(!advancedMode)}
                    />
                </div> */}
      </SectionHeader>
      <ServiceSuiteServicesList
        services={props.serviceSuite.services}
        switchView={advancedMode}
        onTestSuiteChanged={props.onTestSuiteChanged}
        onServiceToggled={props.onServiceToggled}
        testSuitesAndroid={props.testSuitesAndroid}
        testSuitesIos={props.testSuitesIos}
        diagnosticDeviceArgumentsByName={props.diagnosticDeviceArgumentsByName}
        onServiceArgumentChanged={props.onServiceArgumentChanged}
        onOrderChanged={props.onOrderChanged}
      />

      <SectionHeader title="Minimum identifiers*" />

      <div className="column centered margin-v--15">
        <Transfer
          className="minimum-identifiers"
          rowKey={(identifier) => identifier.name}
          /**
           * As of antd@3.26.20, Transfer uses rowKey() to *mutate* the .key property on the dataSource items.
           * This throws an error because the dataSource items here come directly from the store and the
           * reducer that sets this part of the store is defined using createReducer(), which internally uses
           * immer for immutability. Immer happens to deeply freeze its return values.
           * As such, that part of the store is *deeply frozen*, which means a runtime error will occur when
           * mutations are attempted.
           * As a workaround, copies of the mutated items must be provided, rather than the originals from the
           * store.
           *
           * Note: immer autoFreeze is disabled for production builds (to avoid this kind of breakage)
           * see ./src/index
           */
          // TODO: remove .map after antd@4.x
          dataSource={props.minimumIdentifiers.map((item) => ({ ...item }))}
          titles={["Available identifiers", "Selected identifiers"]}
          targetKeys={props.serviceSuite.minimumIdentifiers}
          showSearch
          onChange={onTransferIdentifiers}
          listStyle={{ width: 300, height: 300 }}
          render={(item) => `${item.displayName.en}`}
        />
        <p className="error-message row to-end">
          {props.minimumIdentifiers.length === 0 ? "No available identifiers found" : null}
        </p>
      </div>

      <SectionHeader title="Required fields" />
      <div className="column centered margin-v--15">
        <Transfer
          className="required-fields"
          rowKey={(identifier) => identifier.name}
          /**
           * As of antd@3.26.20, Transfer uses rowKey() to *mutate* the .key property on the dataSource items.
           * This throws an error because the dataSource items here come directly from the store and the
           * reducer that sets this part of the store is defined using createReducer(), which internally uses
           * immer for immutability. Immer happens to deeply freeze its return values.
           * As such, that part of the store is *deeply frozen*, which means a runtime error will occur when
           * mutations are attempted.
           * As a workaround, copies of the mutated items must be provided, rather than the originals from the
           * store.
           *
           * Note: immer autoFreeze is disabled for production builds (to avoid this kind of breakage)
           * see ./src/index
           */
          // TODO: remove .map after antd@4.x
          dataSource={props.requiredIdentifiers.map((item) => ({ ...item }))}
          titles={["Available required fields", "Selected required fields"]}
          targetKeys={props.serviceSuite.requiredFields}
          showSearch
          onChange={onTransferRequiredFields}
          listStyle={{ width: 300, height: 300 }}
          render={(item) => `${item.displayName.en}`}
        />
        <p className="error-message row to-end">
          {props.requiredIdentifiers.length === 0 ? "No available required fields found" : null}
        </p>
      </div>

      <SectionHeader title="Custom permissions" />
      <div className="column centered margin-v--15">
        <Transfer
          className="custom-permissions"
          rowKey={(identifier) => identifier.name}
          /**
           * As of antd@3.26.20, Transfer uses rowKey() to *mutate* the .key property on the dataSource items.
           * This throws an error because the dataSource items here come directly from the store and the
           * reducer that sets this part of the store is defined using createReducer(), which internally uses
           * immer for immutability. Immer happens to deeply freeze its return values.
           * As such, that part of the store is *deeply frozen*, which means a runtime error will occur when
           * mutations are attempted.
           * As a workaround, copies of the mutated items must be provided, rather than the originals from the
           * store.
           *
           * Note: immer autoFreeze is disabled for production builds (to avoid this kind of breakage)
           * see ./src/index
           */
          // TODO: remove .map after antd@4.x
          dataSource={props.customPermissions.map((item) => ({ ...item }))}
          titles={["Available custom permissions", "Selected custom permissions"]}
          targetKeys={props.serviceSuite.customPermissions}
          showSearch
          onChange={onTransferCustomPermissions}
          listStyle={{ width: 450, height: 300 }}
          render={(item) => `${item.displayName.en}`}
        />
        <p className="error-message row to-end">
          {props.customPermissions.length === 0 ? "No available custom permissions found" : null}
        </p>
      </div>

      <WorkflowEditor />

      <div className="cc-details-panel row to-end">
        {!props.serviceSuiteRequireFilled ? (
          <div className="info-message column centered">
            All fields marked with an asterisk (*) are required.
          </div>
        ) : null}
        <AsciButton color="white" onClick={props.onClearEdition}>
          {props.serviceSuiteDidChange ? "Cancel" : "Exit"}
        </AsciButton>
        {isEditing ? (
          <AsciButton
            color="green"
            onClick={() => props.onSave(props.serviceSuite)}
            disabled={props.saveDisabled || errorIdentifier}
          >
            Save service suite
          </AsciButton>
        ) : (
          <AsciButton
            color="green"
            onClick={() => props.onAdd(props.serviceSuite)}
            disabled={props.saveDisabled || errorIdentifier}
          >
            Add service suite
          </AsciButton>
        )}
      </div>
    </div>
  );
};

ServiceSuiteEditor.defaultProps = {
  minimumIdentifiers: [],
  requiredIdentifiers: [],

  identifiers: [],
  testSuitesAndroid: [],
  testSuitesIos: [],
  saveDisabled: true,
  serviceSuiteDidChange: true,
  serviceSuiteRequireFilled: false,
  fetchGroupPriorities: () => {},
};

export default ServiceSuiteEditor;
