import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { TextField } from "react-md";
import { Row, Column } from "~/global";
import { getRegexValidation } from "~/global/utils";
import moduleAction from "~/data-import/actions/";

import "./IdentifyingInfo.component.scss";

const IdentifyingInfo = ({
  saveIdenfyingInfo,
  imeiFromState,
  meidFromState,
  bluetoothMACFromState,
  wifiMACFromState,
  OEMFromState,
}) => {
  const [imei, setImei] = useState("");
  const [meid, setMeid] = useState("");
  const [wifiMAC, setWifiMAC] = useState("");
  const [bluetoothMAC, setBluetoothMAC] = useState("");
  const [OEMSerialNumber, setOemSerialNumber] = useState("");
  const [imeiValid, setImeiValid] = useState(true);
  const [meidValid, setMeidValid] = useState(true);
  const [wifiMacValid, setWifiMacValid] = useState(true);
  const [bluetoothMacValid, setBluetoothMacValid] = useState(true);
  const [oemNumberValid, setOemNumberValid] = useState(true);

  useEffect(() => {
    setImeiValid(imei === "" ? true : getRegexValidation("IMEI", imei));
    setMeidValid(meid === "" ? true : getRegexValidation("MEID", meid));
    setWifiMacValid(wifiMAC === "" ? true : getRegexValidation("wifiMAC", wifiMAC));
    setBluetoothMacValid(
      bluetoothMAC === "" ? true : getRegexValidation("bluetoothMAC", bluetoothMAC)
    );
    setOemNumberValid(
      OEMSerialNumber === "" ? true : getRegexValidation("OEMSerialNumber", OEMSerialNumber)
    );
  }, [imei, meid, wifiMAC, bluetoothMAC, OEMSerialNumber]);

  useEffect(() => {
    const allNonEmptyFieldsAreValid =
      (imei === "" || imeiValid) &&
      (meid === "" || meidValid) &&
      (wifiMAC === "" || wifiMacValid) &&
      (bluetoothMAC === "" || bluetoothMacValid) &&
      (OEMSerialNumber === "" || oemNumberValid);

    saveIdenfyingInfo({
      imei,
      meid,
      wifiMAC,
      bluetoothMAC,
      OEMSerialNumber,
      isIdentifyingInfoValid: allNonEmptyFieldsAreValid,
    });
  }, [
    imei,
    meid,
    wifiMAC,
    bluetoothMAC,
    OEMSerialNumber,
    imeiValid,
    meidValid,
    wifiMacValid,
    bluetoothMacValid,
    oemNumberValid,
  ]);

  useEffect(() => {
    if (imeiFromState === "") setImei("");
    if (meidFromState === "") setMeid("");
    if (wifiMACFromState === "") setWifiMAC("");
    if (bluetoothMACFromState === "") setBluetoothMAC("");
    if (OEMFromState === "") setOemSerialNumber("");
  }, [imeiFromState, meidFromState, wifiMACFromState, bluetoothMACFromState, OEMFromState]);

  return (
    <div className="identifying-info">
      <Row className="section-title">Identifying Info</Row>
      <Row>
        <Column className="info-column">
          <Row className="field-label">IMEI</Row>
          <Row>
            <TextField
              id="imei-input"
              placeholder="Enter IMEI"
              onChange={(value) => setImei(value)}
              type="text"
              className="input-field"
              value={imei}
            />
          </Row>
          {!imeiValid && (
            <Row className="error-message">
              A valid IMEI value is required: 15 characters, numeric, no special characters allowed.
              Example: ‘123435676547686’
            </Row>
          )}
        </Column>
        <Column className="info-column">
          <Row className="field-label">MEID</Row>
          <Row>
            <TextField
              id="meid-input"
              placeholder="Enter MEID"
              onChange={(value) => setMeid(value)}
              type="text"
              className="input-field"
              value={meid}
            />
          </Row>
          {!meidValid && (
            <Row className="error-message">
              A valid MEID is required: Only letters and numbers for an exact length of 14. Example:
              ‘1234567890ABDC’
            </Row>
          )}
        </Column>
        <Column className="info-column">
          <Row className="field-label">WifiMAC</Row>
          <Row>
            <TextField
              id="wifi-mac-input"
              placeholder="Enter WifiMAC"
              onChange={(value) => setWifiMAC(value)}
              type="text"
              className="input-field"
              value={wifiMAC}
            />
          </Row>
          {!wifiMacValid && (
            <Row className="error-message">
              A valid Wi-Fi MAC is required: Six groups of two hexadecimal digits separated by a
              colon. Example: ‘0A:1B:2C:3D:4E:FF’
            </Row>
          )}
        </Column>
        <Column className="info-column">
          <Row className="field-label">bluetoothMAC</Row>
          <Row>
            <TextField
              id="bluetooth-mac-input"
              placeholder="Enter bluetoothMAC"
              onChange={(value) => setBluetoothMAC(value)}
              type="text"
              className="input-field"
              value={bluetoothMAC}
            />
          </Row>
          {!bluetoothMacValid && (
            <Row className="error-message">
              A valid Bluetooth MAC adress is required: Six groups of two hexadecimal digits
              separated by a colon. Example: ‘00:11:22:33:FF:EE’
            </Row>
          )}
        </Column>
        <Column className="info-column">
          <Row className="field-label">OEMSerialNumber</Row>
          <Row>
            <TextField
              id="oem-serial-number-input"
              placeholder="Enter SerialNumber"
              onChange={(value) => setOemSerialNumber(value)}
              type="text"
              className="input-field"
              value={OEMSerialNumber}
            />
          </Row>
          {!oemNumberValid && (
            <Row className="error-message">
              A valid OEM Serial Number is required: Only contains number and letters. Example:
              ‘12345ABCDE’
            </Row>
          )}
        </Column>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  imeiFromState: state.modules.dataImport.importDataManual.imei,
  meidFromState: state.modules.dataImport.importDataManual.meid,
  wifiMACFromState: state.modules.dataImport.importDataManual.wifiMAC,
  bluetoothMACFromState: state.modules.dataImport.importDataManual.bluetoothMAC,
  OEMFromState: state.modules.dataImport.importDataManual.OEMSerialNumber,
});

const mapDispatchToProps = {
  saveIdenfyingInfo: moduleAction.importDataManual.updateIdentiyingInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentifyingInfo);
