import React, { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Space, Table } from "antd";
import { useTranslation } from "~/i18n";
import {
  inventoryProjectsSelectors,
  InventoryProject,
  deleteInventoryProject,
} from "../../../entities/inventory/model/inventoryProjetsSlice";
import { useAppDispatch, useAppSelector } from "~/hooks";

interface InventoryProjectsTableProps {
  setProjectToEdit: (project: InventoryProject) => void;
  scrollToTop: () => void;
}

export const InventoryProjectsTable: React.FC<InventoryProjectsTableProps> = ({
  setProjectToEdit,
  scrollToTop,
}) => {
  const { t } = useTranslation("inventory");
  const dispatch = useAppDispatch();

  const { listStatus } = useAppSelector((state) => state.inventoryProjects);
  const allProjects = useAppSelector(inventoryProjectsSelectors.selectAll);

  const [isVisible, setIsVisible] = useState(true); // État pour contrôler la visibilité

  return (
    <Card
      className="inventory-projects"
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>{t("inventory-projects")}</span>
          <Button
            type="text"
            icon={isVisible ? <UpOutlined /> : <DownOutlined />}
            onClick={() => setIsVisible(!isVisible)}
          />
        </div>
      }
      bordered={false}
      bodyStyle={{
        overflow: "hidden",
        padding: isVisible ? "24px" : "0",
        transition: "height 0.3s ease, padding 0.3s ease",
        height: isVisible ? "auto" : "0",
      }}
    >
      {isVisible && (
        <Table
          columns={[
            {
              title: t("project-name"),
              dataIndex: "projectName",
              sorter: (a, b) => a.projectName.localeCompare(b.projectName),
            },
            {
              title: t("facility"),
              dataIndex: "facility",
              sorter: (a, b) => a.facility.localeCompare(b.facility),
            },
            {
              title: t("location"),
              dataIndex: "location",
              sorter: (a, b) => a.location.localeCompare(b.location),
            },
            {
              title: t("supervisor-name"),
              dataIndex: "supervisorName",
              sorter: (a, b) => a.supervisorName.localeCompare(b.supervisorName),
              render: (supervisorName: string) => {
                const parts = supervisorName.split(":");
                return parts.length > 1 ? parts[0] : supervisorName;
              },
            },
            {
              title: t("status"),
              dataIndex: "status",
              filters: [
                { text: t("in-progress"), value: "In Progress" },
                { text: t("completed"), value: "Completed" },
                { text: t("pending"), value: "Pending" },
              ],
              onFilter: (value, record) => record.status === value,
            },
            {
              title: t("active"),
              dataIndex: "active",
              render: (active: boolean) => (active ? t("yes") : t("no")),
              filters: [
                { text: t("yes"), value: true },
                { text: t("no"), value: false },
              ],
              onFilter: (value, record) => record.active === value,
            },
            {
              title: t("actions"),
              key: "actions",
              align: "right",
              width: 80,
              render: (_: any, project: InventoryProject) => (
                <Space>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setProjectToEdit(project);
                      scrollToTop();
                    }}
                  />

                  <Popconfirm
                    title={t("delete-confirmation")}
                    okText={t("yes")}
                    okType="danger"
                    cancelText={t("no")}
                    onConfirm={() => {
                      dispatch(deleteInventoryProject(project.id))
                        .unwrap()
                        .then(() => {
                          void message.success(t("delete-success"));
                        })
                        .catch((rejectedValue: string) => {
                          void message.error(rejectedValue);
                        });
                    }}
                  >
                    <Button danger shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
          dataSource={allProjects}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
          }}
          loading={
            listStatus === "loading"
              ? {
                  indicator: <LoadingOutlined style={{ color: "#005288" }} />,
                  size: "large",
                }
              : false
          }
        />
      )}
    </Card>
  );
};
