import React from "react";
import { Redirect } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";

import { useAppSelector } from "~/hooks";
import selectors from "~/selectors";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- React Router does not provide a props type for the CompatRoute
interface Props extends Record<string, any> {
  appName: string;
}

const RouteWithPermission = ({ appName, ...rest }: Props) => {
  const isUserProfileLoading = useAppSelector((state) => state.session.isUserProfileLoading);
  const availableApps = useAppSelector(selectors.session.getAvailableApps);

  const isAppAvailable = useAppSelector((state) =>
    selectors.session.isAppAvailable(state, appName)
  );

  // Puis effectuer les rendus conditionnels
  if (isUserProfileLoading || !availableApps) {
    return <div>Chargement des permissions…</div>;
  }

  if (isAppAvailable) {
    return <CompatRoute {...rest} />;
  }
  return <Redirect to="/" />;
};

export default RouteWithPermission;
